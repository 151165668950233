import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import axios from "axios"
interface WorkingTask {
  _id: string;
  PmId: string;
  PracticeId: string;
  TaskId: string;
  WorkingHours: number;
  Target: string;
  TargetUnit: string;
  ProcessName: string;
  ProofNeed: string;
  FileName: string;
}

interface DataItem {
  _id: {
    UserId : "0",
    WorkingDate : "0"
  };
  userName: string;
  RoleName: string;
  WorkingStatus: string;
  PracticeName: string;
  WorkingDate: string;
  UserId: string;
  TotalCount: number;
  TotalEfficiency: number;
  OthersProduction: number;
  Count: number;
  PCA: number;
  Efficiency: number;
  PCAEfficiency: number;
  WorkingTaskList: any;
  AttachmentName:String;
}

interface TableProps {
  data: DataItem[];
  RoleName: String,
}

const DataTable: React.FC<TableProps> = ({ data, RoleName}) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const toggleRow = (rowId: string) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };
  const grandTotalOthersEfficiency = data.reduce((acc, item) => acc + item.OthersProduction, 0);
  const grandTotalEfficiency = data.reduce((acc, item) => acc + item.Efficiency, 0);
  const DownloadFile = async (e: any,AttachmentName:String) => {
    e.preventDefault();
    setOpen(true)

    await axios.get(e.target.href, { responseType: 'arraybuffer', }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${AttachmentName}`);
        setOpen(false);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => console.log(error));
    return false;
}
  return (
    <>
      <Table bordered hover align-middle table-row-dashed >
        <thead>
          <tr className='text-center'>
            <th>#</th>
            <th>Working Date</th>
            <th>Practice Name</th>
            <th>Total Count</th>
            {
              RoleName != "AR-Caller" ? <><th >PCA</th><th >PCA Efficiency</th></> : null
            }
            <th> Efficiency</th>
            <th>Others Efficiency</th>
            <th>Total Efficiency</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            
            <React.Fragment key={item._id.WorkingDate} >
              {item.WorkingStatus == "Yes" ?
              <>
                {Object.keys(item.WorkingTaskList[0].TaskDetails).length > 0 ? 

                  <tr className='text-center'>
                  <td>{index + 1}</td>
                  <td>{item._id.WorkingDate}</td>
                  <td>{item.PracticeName}</td>
                  <td>{item.WorkingTaskList[0].Count}</td>
                  {
                    RoleName != "AR-Caller" ? <>
                    <td>{item.WorkingTaskList[0].PCA}</td>
                    <td>{item.WorkingTaskList[0].PCAEfficiency}%</td>
                    </> : null
                  }
                  <td>{item.WorkingTaskList[0].Efficiency}%</td>
                  <td>{item.WorkingTaskList[0].OthersProduction}%</td>
                  <td>{Number(Number(item.WorkingTaskList[0].Efficiency) + Number(item.WorkingTaskList[0].OthersProduction) + Number(item.WorkingTaskList[0].PCAEfficiency)).toFixed(2)}%</td>
                  <td>

                    {item.AttachmentName ? 
                      <a  onClick={(e) => { DownloadFile(e,item.AttachmentName) }} href={`/api/ProductionTrack/DownloadFile?UserId=${item._id.UserId}&WorkingDate=${item._id.WorkingDate}&PracticeId=${item.WorkingTaskList[0].PracticeId}&FileName=${item.AttachmentName}`} className='fa fa-download m-1'></a>  : null
                    }
                    <a href="#" className={expandedRow === item._id.WorkingDate ? 'm-1 fa fa-minus' : 'm-1 fa fa-plus-square'} onClick={() => toggleRow(item._id.WorkingDate)}></a>
                  </td>
                  </tr> : <tr className='text-center'>
                  <td>{index + 1}</td>
                  <td>{item._id.WorkingDate}</td>
                  <td colSpan={RoleName != "AR-Caller" ? 7 : 5} className='text-center'> No Task Details Found</td>
                  </tr>
                }
              </>
              
               :  
                <tr className='text-center'>
                   <td>{index + 1}</td>
                  <td>{item._id.WorkingDate}</td>
                  <td colSpan={RoleName != "AR-Caller" ? 7 : 5} className='text-center'>Absent</td>
               
                </tr>
              }
              {expandedRow === item._id.WorkingDate && (
                <tr>
                  <td colSpan={10}>

                    <div className='badge-dark text-center'> Task Histroy</div>

                    <Table size="sm">
                      <thead >
                        <tr className='text-center'>
                          <th>S.No</th>
                          <th>Process Name</th>
                          <th>Working Hours</th>
                          <th>Target</th>
                          <th>Target Unit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.WorkingTaskList.map((task:any, idx:any) => (
                          <tr className='text-center' key={task._id}>
                            <td>{idx + 1}</td>
                            <td>{task.TaskDetails.ProcessName}</td>
                            <td>{task.TaskDetails.WorkingHours}</td>
                            <td>{task.TaskDetails.Target}</td>
                            <td>{task.TaskDetails.TargetUnit}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          {/* <tr className='text-center font-weight-bold'>
            <td colSpan={3} style={{ textAlign: 'right' }}>Grand Total:</td>
            {RoleName !== "AR-Caller" && <td></td>}
            <td>{grandTotalEfficiency}%</td>
            <td>{ Number(grandTotalOthersEfficiency).toFixed(2)}%</td>
            <td>{Number(Number(grandTotalEfficiency) + Number(grandTotalOthersEfficiency)).toFixed(2)}%</td>
            
            <td></td>
          </tr> */}
        </tbody>
      </Table>
    </>

  );
};

export default DataTable;
