import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom';
import { requestPassword } from '../core/_requests'
import { Auth } from 'aws-amplify';
import swal from "sweetalert"
const initialValues = {
  email: '',
  code: '',
  newpassword: '',
  confirmpassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  // newpassword: Yup.string().required('New Password is required'),
  // confirmpassword: Yup.string().required('Confirm Password is required'),
  // code: Yup.string().required('Confirmation Code is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetComplete, setResetComplete] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log("values", values)
      setLoading(true)
      setHasErrors(undefined)
      if (resetComplete) {
        try {
          await Auth.forgotPasswordSubmit(values.email, values.code, values.newpassword);
          setResetComplete(true);
          navigate("/auth");
        } catch (error: any) {
          swal({
            title: 'Invalid',
            icon: "info",
            text: `${error.message}`,
          }).then((_res: any) => {

          })
          setErrorMsg(error.message);
        }
      } else {
        try {
          await Auth.forgotPassword(values.email);
          setResetComplete(true);
        } catch (error: any) {
          swal({
            title: 'Invalid',
            icon: "info",
            text: `${error.message}`,
          }).then((_res: any) => {

          })
        }
      }

    },
  })

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    try {
      await Auth.forgotPassword(username);
      setResetComplete(true);
    } catch (error: any) {
      setErrorMsg(error.message);
    }
  };

  const handleConfirmCode = async (event: any) => {
    event.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      setResetComplete(true);
    } catch (error: any) {
      setErrorMsg(error.message);
    }
  };

  return (
    <div className='w-lg-500px p-10'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          {/* <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div> */}
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {resetComplete ?
          <>
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Confirmation Code</label>
              <input
                type='text'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('code')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.code && formik.errors.code },
                  {
                    'is-valid': formik.touched.code && !formik.errors.code,
                  }
                )}
              />
              {formik.touched.code && formik.errors.code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.code}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('newpassword')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.newpassword && formik.errors.newpassword },
                  {
                    'is-valid': formik.touched.newpassword && !formik.errors.newpassword,
                  }
                )}
              />
              {formik.touched.newpassword && formik.errors.newpassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.newpassword}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('confirmpassword')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.confirmpassword && formik.errors.confirmpassword },
                  {
                    'is-valid': formik.touched.confirmpassword && !formik.errors.confirmpassword,
                  }
                )}
              />
              {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirmpassword}</span>
                  </div>
                </div>
              )}
            </div>
          </>



          : null}
        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
