import React, { useState, useEffect, HTMLAttributes } from 'react'
import axios, { AxiosResponse } from "axios";
import moment from "moment"
import { useLocation, useNavigate } from 'react-router-dom'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert"
import CircularProgress from '@mui/material/CircularProgress';
import '../../../_metronic/assets/css/global.css'
import TableRows from '../../components/TableRows';
import AgingTable from '../../components/AgingTable';
import DataTable from './DataTable';

const API_URL = process.env.REACT_APP_THEME_API_URL

const getClaimbyUser = `/api/Claims/getClaimbyUser`
const options = { style: 'currency', currency: 'USD' };

interface TableHeaderCellProps extends HTMLAttributes<HTMLTableCellElement> {
    colSpan: number;
}


type GrandTotalType = {
    totalAmount0: number
    totalCount0: number
    totalAmount60: number
    totalCount60: number
    totalAmount120: number
    totalCount120: number
}

type AgingTotalType = {
    totalAmount: number
    totalCount: number
}

const thead: React.CSSProperties = {
    color: "#fff",
    backgroundColor: "#002d72",
    top: 70,
    position: 'sticky',
};
const Back: React.CSSProperties = {
    background: `linear-gradient(90deg, rgba(232,217,207,1) 17%, rgba(170,208,194,1) 100%, rgba(193,100,82,1) 100%)`,

};
const arrow_right: React.CSSProperties = {
    width: "30px",
    height: "30px",

};


const ViewPersonDashboard: React.FC = () => {
    const [CurrentPayerName, setCurrentPayerName] = React.useState({ UserId: "" });
    const [FromDate, setFromDate] = useState()
    const [ToDate, setToDate] = useState()
    const [UserName, setUserName] = useState<string>()
    const navigate = useNavigate();
    const location = useLocation();
    const userDetails: any = location.state;
    const [userDetailsState, setUserDetailsState] = useState()
    const [DataForValidate, setDataForValidate] = useState<any>([]);
    const [Loading, setLoading] = useState<boolean>(false)
    const [duedate, setduedate] = React.useState("");
    const [Comments, setComments] = React.useState("");

    //payer wise
    const [ChartData, setChartData] = useState<any>([])
    const [ChartDataForClaim, setChartDataForClaim] = useState<any>([])
    const [PayerNameTableData, setPayerNameTableData] = useState<any>([])


    //status wise
    const [ChartStatusWiseData, setChartStatusWiseData] = useState<any>([])
    const [ChartStatusWiseDataForClaim, setChartStatusWiseDataForClaim] = useState<any>([])
    const [StatusWiseTableData, setStatusWiseTableData] = useState<any>([])

    //Provider wise
    const [ChartProviderData, setChartProviderData] = useState<any>([])
    const [ChartProviderDataForClaim, setChartProviderDataForClaim] = useState<any>([])
    const [ProviderWiseTableData, setProviderWiseTableData] = useState<any>([])


    //Rootcause wise
    const [ChartRootCauseData, setChartRootCauseData] = useState<any>([])
    const [ChartRootCauseDataForClaim, setChartRootCauseDataForClaim] = useState<any>([])
    const [RootCauseTableData, setRootCauseTableData] = useState<any>([])

    //Practice wise 
    const [ChartPracticeWiseData, setChartPracticeWiseData] = useState<any>([])
    const [ChartPracticeWiseDataForClaim, setChartPracticeWiseDataForClaim] = useState<any>([])
    const [PracticeWiseTableData, setPracticeWiseTableData] = useState<any>([])

    //Aging wise
    const [ChartAgingWiseData, setChartAgingWiseData] = useState([])
    const [ChartAgingWiseDataForClaim, setChartAgingWiseDataForClaim] = useState([])
    const [AgingeTableData, setAgingeTableData] = useState([])


    const [SelectedValue, setSeSelectedValue] = React.useState({ label: "Select", value: "" })
    const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
    const [selectedRadio, setSelectedRadio] = useState<string>('');
    // const [myLoading, setMyLoading] = useState(false)
    const [CallerId, setCallerId] = useState<string>("")
    const [GrandTotal, setGrandTotal] = useState<GrandTotalType>({
        totalAmount0: 0,
        totalCount0: 0,
        totalAmount60: 0,
        totalCount60: 0,
        totalAmount120: 0,
        totalCount120: 0
    })

    const [AgingTotal, setAgingTotal] = useState<AgingTotalType>({
        totalAmount: 0,
        totalCount: 0,

    })
    //payer chart
    const ChartOptionsForClaim = {
        title: "Payer Wise vs No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Payer Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf', colors: ['#c16452', 'transparent'],
    }

    const ChartOptions = {
        title: "Payer Wise vs Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Payer Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } },
        colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }

    //status chart
    const ChartOptionsStatusForClaim = {
        title: "Status Wise vs No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Status Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf', colors: ['#c16452', 'transparent'],
    }
    const ChartOptionsStatus = {
        title: "Status Wise vs Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Status Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } },
        colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }


    //provider chart
    const ChartOptionsProviderForClaim = {
        title: "Provider Wise vs No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Provider Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf', colors: ['#c16452', 'transparent'],
    }

    const ChartOptionsProvider = {
        title: "Provider Wise vs Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Provider Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } },
        colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }

    //Rootcause chart
    const ChartOptionsRootCauseForClaim = {
        title: "RootCause Wise vs No of claims", fontSize: 10, isStacked: true, hAxis: { title: "RootCause Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf', colors: ['#c16452', 'transparent'],
    }

    const ChartOptionsRootCause = {
        title: "RootCause Wise vs Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "RootCause Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } },
        colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }

    //Practice chart
    const ChartOptionsPracticeForClaim = {
        title: "Practice Wise vs No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Practice Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf', colors: ['#c16452', 'transparent'],
    }

    const ChartOptionsPractice = {
        title: "Practice Wise vs Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Practice Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } },
        colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }

    //Aging chart
    const ChartOptionsAgingForClaim = {
        title: "Aging Wise vs No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Aging Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf', colors: ['#c16452', 'transparent'],
    }

    const ChartOptionsAging = {
        title: "Aging Wise vs Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Aging Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } },
        colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }



    useEffect(() => {

        if (userDetails === null) {
            const storedData = sessionStorage.getItem("ProdCallerId");

            if (storedData !== null) {
                const parseObj: any = JSON.parse(storedData);
                setCallerId(parseObj.UserId)
                setFromDate(parseObj.fromDate)
                setToDate(parseObj.toDate)
                setUserName(parseObj.UserName)
            } else {
                GoToBack();
            }
        } else {
            const obj = {
                UserId: userDetails.UserId,
                fromDate: userDetails.fromDate,
                toDate: userDetails.toDate,
                UserName: userDetails.UserName
            }
            sessionStorage.setItem("ProdCallerId", JSON.stringify(obj))
            setCallerId(userDetails.UserId)
            setFromDate(userDetails.fromDate)
            setToDate(userDetails.toDate)
            setUserName(userDetails.UserName)
        }
    }, [])

    const DownloadFile = async (e:any)=>{
        var radioValue = "Others Production"
        setLoading(true)
        e.preventDefault();
        await axios.get(`${getClaimbyUser}?FromDate=${FromDate}&ToDate=${ToDate}&radio=${radioValue}&UserId=${CallerId}&PageType=download`,{responseType: 'arraybuffer',}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `User Production-${FromDate} - ${ToDate}.xlsx`);
            document.body.appendChild(link);
            setLoading(false)
            link.click();
        }).catch((error) => console.log(error));
        return false;
    }
    
    async function fetchData(radioValue: string) {
        setSelectedRadio(radioValue)
        setChartData([])
        setDataForValidate([])
        setChartDataForClaim([])
        setPayerNameTableData([])
        setChartStatusWiseData([])
        setChartStatusWiseDataForClaim([])
        setStatusWiseTableData([])
        setChartProviderData([])
        setChartProviderDataForClaim([])
        setProviderWiseTableData([])
        setChartRootCauseData([])
        setChartRootCauseDataForClaim([])
        setRootCauseTableData([])
        setChartPracticeWiseData([])
        setChartPracticeWiseDataForClaim([])
        setPracticeWiseTableData([])
        setChartAgingWiseData([])
        setChartAgingWiseDataForClaim([])
        setAgingeTableData([])
        setLoading(true)
        setGrandTotal({
            totalAmount0: 0,
            totalCount0: 0,
            totalAmount60: 0,
            totalCount60: 0,
            totalAmount120: 0,
            totalCount120: 0
        })
        setAgingTotal({
            totalAmount: 0,
            totalCount: 0
        })


        if (CallerId.length > 0) {
            await axios.get(`${getClaimbyUser}?UserId=${CallerId}&radio=${radioValue}&FromDate=${FromDate}&ToDate=${ToDate}`).then(_res => {

                if (_res.data.Radio === "PAYER WISE") {
                    setChartData(_res.data.PayerNameData)
                    setChartDataForClaim(_res.data.PayerNameDataForClaim)
                    setPayerNameTableData(_res.data.PayerNameDataTable)
                    setLoading(false)
                    setGrandTotal({
                        totalAmount0: _res.data.totalAmount0,
                        totalCount0: _res.data.totalCount0,
                        totalAmount60: _res.data.totalAmount60,
                        totalCount60: _res.data.totalCount60,
                        totalAmount120: _res.data.totalAmount120,
                        totalCount120: _res.data.totalCount120
                    })
                }

                if (_res.data.Radio === "STATUS WISE") {
                    setChartStatusWiseData(_res.data.StatusWiseData)
                    setChartStatusWiseDataForClaim(_res.data.StatusWiseDataForClaim)
                    setStatusWiseTableData(_res.data.StatusWiseTableData)
                    setGrandTotal({
                        totalAmount0: _res.data.totalAmount0,
                        totalCount0: _res.data.totalCount0,
                        totalAmount60: _res.data.totalAmount60,
                        totalCount60: _res.data.totalCount60,
                        totalAmount120: _res.data.totalAmount120,
                        totalCount120: _res.data.totalCount120
                    })
                    setLoading(false)
                }
                if (_res.data.Radio === "PROVIDER WISE") {
                    setChartProviderData(_res.data.ProviderNameWiseData)
                    setChartProviderDataForClaim(_res.data.ProviderNameWiseDataForClaim)
                    setProviderWiseTableData(_res.data.ProviderWiseTableData)
                    setGrandTotal({
                        totalAmount0: _res.data.totalAmount0,
                        totalCount0: _res.data.totalCount0,
                        totalAmount60: _res.data.totalAmount60,
                        totalCount60: _res.data.totalCount60,
                        totalAmount120: _res.data.totalAmount120,
                        totalCount120: _res.data.totalCount120
                    })
                    setLoading(false)
                }
                if (_res.data.Radio === "ROOTCAUSE WISE") {
                    setChartRootCauseData(_res.data.RootCauseData)
                    setChartRootCauseDataForClaim(_res.data.RootCauseDataForClaim)
                    setRootCauseTableData(_res.data.RootCauseWiseTableData)
                    setGrandTotal({
                        totalAmount0: _res.data.totalAmount0,
                        totalCount0: _res.data.totalCount0,
                        totalAmount60: _res.data.totalAmount60,
                        totalCount60: _res.data.totalCount60,
                        totalAmount120: _res.data.totalAmount120,
                        totalCount120: _res.data.totalCount120
                    })
                    setLoading(false)
                }
                if (_res.data.Radio === "PRACTICE WISE") {
                    setChartPracticeWiseData(_res.data.PracticeWiseData)
                    setChartPracticeWiseDataForClaim
                        (_res.data.PracticeWiseDataForClaim)
                    setPracticeWiseTableData(_res.data.PracticeWiseTableData)
                    setGrandTotal({
                        totalAmount0: _res.data.totalAmount0,
                        totalCount0: _res.data.totalCount0,
                        totalAmount60: _res.data.totalAmount60,
                        totalCount60: _res.data.totalCount60,
                        totalAmount120: _res.data.totalAmount120,
                        totalCount120: _res.data.totalCount120
                    })
                    setLoading(false)
                }
                if (_res.data.Radio === "AGING WISE") {
                    setChartAgingWiseData(_res.data.AgeingWiseChartData)
                    setChartAgingWiseDataForClaim
                        (_res.data.AgeingWiseChartDataForClaim)
                    setAgingeTableData(_res.data.AgeingWiseTableData)
                    setAgingTotal({
                        totalAmount: _res.data.totalAmount,
                        totalCount: _res.data.totalCount
                    })
                    setLoading(false)
                }
                if (_res.data.Radio === "Others Production") {
                    setDataForValidate(_res.data.OthersProduction)
                    setLoading(false)
                }
                

            })


        }
    }

    const handleRadioChange = async (e: any) => {
        fetchData(e.target.value)
    }

    const Reload = () => {
        fetchData(selectedRadio)
    }





    const handleChangeUser = (event: any) => {
        setSeSelectedValue(event)
    };

    const GoToBack = () => {
        navigate('/dashboard')
    }
    const handleChangeInput = (event: any) => {

        if (event.target.name === "duedate") {
            setduedate(event.target.value)
        }
        if (event.target.name === "Comments") {
            setComments(event.target.value)
        }
        if (event.target.name === "UpdateCommentsFiled") {
            setComments(event.target.value)
        }
    };

    var PmListData = [{ label: "Select", value: "" }]
    getPmListData.map((name) => (
        PmListData.push({
            label: name.PmName,
            value: name._id
        })
    ))

    return (
        <div >

            <div className="header">
                <span className="section">Dashboard</span>
                <span className="arrow">&rsaquo;</span>
                <span className='section'>Over all Caller Dashboard</span>
                <span className="arrow">&rsaquo;</span>
                <span className='section'>Caller Dashboard</span>
            </div>


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={Loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className='row'>
                <div className='d-flex'>
                    <div className='mx-4 pb-1'>
                        <button type='button' value="Ar" onClick={GoToBack} className='btn btn-primary' >Back</button>

                    </div>
                    <div className='mx-2 pb-1'>
                        {selectedRadio ? <button type='button' value="Ar" onClick={Reload} className='btn btn-primary' >Reload</button> : <button type='button' value="Ar" className='btn btn-primary' disabled>Reload</button>}

                    </div>
                </div>

                <span className='my-4'>Showing results :  <h4>{UserName}</h4></span>

                <hr />
                <h3 className='mb-6'>See reports by </h3>

                <div className='radios mb-6 d-flex justify-content-around'>
                    <div className="form-check form-check-inline ">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                            value="PAYER WISE"
                            onChange={handleRadioChange}
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            PAYER WISE
                        </label>
                    </div>

                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="STATUS WISE" onChange={handleRadioChange} />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            STATUS WISE
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="PROVIDER WISE" onChange={handleRadioChange} />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            PROVIDER WISE
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="ROOTCAUSE WISE" onChange={handleRadioChange} />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            ROOTCAUSE WISE
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="PRACTICE WISE" onChange={handleRadioChange} />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            PRACTICE WISE
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="AGING WISE" onChange={handleRadioChange} />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            AGING WISE
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            value="Others Production" onChange={handleRadioChange} />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            Others Production
                        </label>
                    </div>
                </div>

                <div>
                   
                {DataForValidate?.length > 0 ? (
                    <div className=' justify-content-center mt-8'>
                        <button type="button" onClick={DownloadFile}  className="btn btn-primary float-end">Download</button>
                        <DataTable data={DataForValidate} RoleName={"AR-Caller"}  />
                    </div>
                ) : (DataForValidate?.length === 0 && selectedRadio === "Others Production") ?
                    <h4 className='text-center'>No Results Found </h4>
                    : null}

                </div>

                {/* Payer wise  */}
                <div>
                    <div className='d-flex flex-row justify-content-around '>

                        {ChartDataForClaim.length > 0 ? (
                            <div className='col-5 shadow'>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartDataForClaim}
                                    options={ChartOptionsForClaim}
                                />
                            </div>

                        ) : null}

                        {ChartData.length > 0 ? (
                            <div className='col-5 shadow '>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartData}
                                    options={ChartOptions}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className='shadow mt-8 '>
                        {PayerNameTableData?.length > 0 ? (
                            <TableRows data={PayerNameTableData} total={GrandTotal} title="PAYER WISE TABLE" />
                        ) : (PayerNameTableData?.length === 0 && selectedRadio === "PAYER WISE") ?
                            <h4 className='text-center'>No Results Found</h4>

                            : null
                        }

                    </div>



                </div>

                {/* status wise */}
                <div>
                    <div className='d-flex flex-row justify-content-around '>

                        {ChartStatusWiseDataForClaim.length > 0 ? (
                            <div className='col-5 shadow'>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartStatusWiseDataForClaim}
                                    options={ChartOptionsStatusForClaim}
                                />
                            </div>

                        ) : null}


                        {ChartStatusWiseData.length > 0 ? (
                            <div className='col-5 shadow '>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartStatusWiseData}
                                    options={ChartOptionsStatus}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className='shadow mt-8 '>
                        {StatusWiseTableData?.length > 0 ? (
                            <TableRows data={StatusWiseTableData} total={GrandTotal} title="STATUS WISE TABLE" />
                        ) : (StatusWiseTableData?.length === 0 && selectedRadio === "STATUS WISE") ?
                            <p className='text-center'>No Results Found</p>

                            : null
                        }
                    </div>
                </div>

                {/* Provider wise */}

                <div>
                    <div className='d-flex flex-row justify-content-around '>

                        {ChartProviderDataForClaim.length > 0 ? (
                            <div className='col-5 shadow'>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartProviderDataForClaim}
                                    options={ChartOptionsProviderForClaim}
                                />
                            </div>

                        ) : null}

                        {ChartProviderData.length > 0 ? (
                            <div className='col-5 shadow '>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartProviderData}
                                    options={ChartOptionsProvider}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className='shadow mt-8 '>
                        {ProviderWiseTableData?.length > 0 ? (
                            <TableRows data={ProviderWiseTableData} total={GrandTotal} title="PROVIDER WISE TABLE" />
                        ) : (ProviderWiseTableData?.length === 0 && selectedRadio === "PROVIDER WISE") ?
                            <h4 className='text-center'>No Results Found</h4>

                            : null
                        }
                    </div>
                </div>

                {/* Practice wise */}

                <div>
                    <div className='d-flex flex-row justify-content-around '>
                        {ChartPracticeWiseDataForClaim.length > 0 ? (
                            <div className='col-5 shadow'>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartPracticeWiseDataForClaim}
                                    options={ChartOptionsPracticeForClaim}
                                />
                            </div>

                        ) : null}


                        {ChartPracticeWiseData.length > 0 ? (
                            <div className='col-5 shadow '>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartPracticeWiseData}
                                    options={ChartOptionsPractice}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className='shadow mt-8 '>
                        {PracticeWiseTableData?.length > 0 ? (
                            <TableRows data={PracticeWiseTableData} total={GrandTotal} title="PAYER WISE TABLE" />
                        ) : (PracticeWiseTableData?.length === 0 && selectedRadio === "PRACTICE WISE") ?
                            <h4 className='text-center'>No Results Found</h4>

                            : null
                        }
                    </div>
                </div>

                {/* RootCause wise */}

                <div>
                    <div className='d-flex flex-row justify-content-around '>

                        {ChartRootCauseDataForClaim.length > 0 ? (
                            <div className='col-5 shadow'>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartRootCauseDataForClaim}
                                    options={ChartOptionsRootCauseForClaim}
                                />
                            </div>

                        ) : null}

                        {ChartRootCauseData.length > 0 ? (
                            <div className='col-5 shadow '>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='500px'
                                    data={ChartRootCauseData}
                                    options={ChartOptionsRootCause}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className='shadow mt-8 '>
                        {RootCauseTableData?.length > 0 ? (
                            <TableRows data={RootCauseTableData} total={GrandTotal} title="ROOTCAUSE WISE TABLE" />
                        ) : (RootCauseTableData?.length === 0 && selectedRadio === "ROOTCAUSE WISE") ?
                            <h4 className='text-center'>No Results Found</h4>

                            : null
                        }
                    </div>
                </div>

                {/* Aging Wise */}
                <div>
                    <div className='d-flex flex-row justify-content-around mt--10'>
                        {ChartAgingWiseDataForClaim.length > 0 ? (
                            <div className='col-5 shadow'>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={ChartAgingWiseDataForClaim}
                                    options={ChartOptionsAgingForClaim}
                                />
                            </div>
                        ) : null}
                        {ChartAgingWiseData.length > 0 ? (
                            <div className='col-5 shadow'>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={ChartAgingWiseData}
                                    options={ChartOptionsAging}
                                />
                            </div>
                        ) : null}

                    </div>
                    <div>
                        <div className='d-flex justify-content-center mt-8'>
                            {AgingeTableData?.length > 0 ? (
                                <AgingTable data={AgingeTableData} total={AgingTotal} title="AGING WISE TABLE" />
                            ) : (AgingeTableData?.length === 0 && selectedRadio === "AGING WISE") ?
                                <h4 className='text-center'>No Results Found </h4>
                                : null}
                        </div>

                    </div>
                </div>



            </div>

        </div>


    )

}
export { ViewPersonDashboard }