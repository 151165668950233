import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import { AnalystDashboardWrapper } from '../pages/dashboard/AnalystDashboardWrapper'
import {AnalystProductivity} from "../pages/dashboard/AnalystProductivity"

import {Practicewise} from "../pages/dashboard/Practicewise"
import {ViewPersonDashboard} from "../pages/dashboard/ViewPersonDashboard"
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ViewClaim} from "../modules/wizards/components/ViewClaim"
import {DumpClaimUpload} from "../modules/wizards/components/DumpClaimUpload"
import {PCA} from "../modules/wizards/components/PCA"
import {ViewClaimCaller} from "../modules/wizards/components/ViewClaimCaller"
import {ClaimCallerViewCompletedClaims} from "../modules/wizards/components/ClaimCallerViewCompletedClaims"
import { Touched } from '../modules/wizards/components/Touched'
import { WeeklyReport } from '../modules/wizards/components/WeeklyReport'
import {BotStatusCheckClaims} from "../modules/wizards/components/BotStatusCheckClaims"
import {ProductionTrack} from "../modules/wizards/components/ProductionTrack"
import UpdateProduction from "../modules/wizards/components/UpdateProduction"
import ValidateProduction from "../modules/wizards/components/ValidateProduction"
import {ARCollectionbyUser} from "../modules/wizards/components/ARCollectionbyUser"
import {ViewClaimsbyid} from "../modules/wizards/components/ViewClaimsbyid"
import {ClaimDetails} from "../modules/wizards/components/ClaimDetails"
import {CreateUser} from "../modules/UserManagment/CreateUser";
import {CreateRole} from "../modules/rolemanagement/CreateRole";
import {RootCause} from "../modules/Practice/RootCause"
import {ViewSystemUsers} from "../modules/Reports/ViewSystemUsers"
import {Reports} from "../modules/Reports/Reports"
import {BotNeedToCheckReport} from "../modules/Reports/BotNeedToCheckReport"
import {BotUtlization} from "../modules/Reports/BotUtlization"
import {Template} from "../modules/SampleTemplate/Template";
import {LineBased} from "../modules/SampleTemplate/LineBased";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const TemplatePage = lazy(() => import('../modules/apps/sample-template/TemplatePage'))
  //const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const Pmsystem = lazy(() => import('../modules/Pmsystem/PmSystem'))
  const CreatePractice = lazy(() => import('../modules/Practice/CreatePractice'))
  const RolePage = lazy(() => import('../modules/apps/role-manegement/RolePage'))
 // const CreateRole = lazy(() => import('../modules/rolemanagement/CreateRole'))
  // const ListRolePage = lazy(() => import('../modules/apps/role-manegement/ListRolePage'))
  const CreateClaimTemplate = lazy(() => import('../modules/ClaimTemplate/CreateClaimTemplate'))
  
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* <Route path='AnalystProductivity' element={<AnalystProductivity />} /> */}
        <Route path='Practicewise' element={<Practicewise />} />
        
        <Route path='PersonDashboard' element={<ViewPersonDashboard />} />
        <Route path='CreateUser' element={<CreateUser />} />
        <Route path='Practice/*' element={<CreatePractice />} />
        <Route path='Template' element={<Template />} />
        <Route path='LineBased' element={<LineBased />} />
        <Route path='Pmsystem/*' element={ <SuspensedView> <Pmsystem /> </SuspensedView> } />
        {/* <Route path='CreateRole' element={<CreateRole />} /> */}
        <Route path='CreateRole' element={<CreateRole />} />
        <Route path='UsersList' element={<UsersPage />} />
        <Route path='RolesList' element={<RolePage />} />
        <Route path='CreateClaimTemplate/*' element={<CreateClaimTemplate />} />
        <Route path='UploadClaim/*' element={<SuspensedView><WizardsPage /></SuspensedView>}
        />
         <Route path='Touched' element={<Touched />} />
        <Route path='DumpClaimUpload' element={<DumpClaimUpload />} />
        <Route path='ClaimDetails' element={<ClaimDetails />} />
        <Route path='ClaimsViewbyid' element={<ViewClaimsbyid />} />
        <Route path='ViewClaim' element={<ViewClaim />} />
        <Route path='ViewClaimbyId' element={<ViewClaimsbyid />} />
        <Route path='PCA' element={<PCA />} />
        <Route path='ViewSystemUsers' element={<ViewSystemUsers />} />
        <Route path='Reports' element={<Reports />} />
        <Route path='BotUtlization' element={<BotUtlization/>} />
        <Route path='AnalystProductivity' element={<AnalystDashboardWrapper />} />
        <Route path='AnalystDashboard' element={<AnalystProductivity />} />
        <Route path='WeeklyReport' element={<WeeklyReport />} />
        
        <Route path='BotNeedToCheckReport' element={<BotNeedToCheckReport />} /> 
        <Route path='BotStatusCheckClaims' element={<BotStatusCheckClaims />} />
        <Route path='ProductionTrack' element={<ProductionTrack />} />
        <Route path='UpdateProduction' element={<UpdateProduction />} />
        <Route path='ValidateProduction' element={<ValidateProduction />} />
        <Route path='ARCollectionbyUser' element={<ARCollectionbyUser />} />
        <Route path='ClaimCallerView' element={<ViewClaimCaller />} />
        <Route path='CompletedClaims' element={<ClaimCallerViewCompletedClaims />} />
        <Route path='AddRootCauseStatus' element={<RootCause />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        
        {/* <Route
          path=''
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/sample-template/*'
          element={
            <SuspensedView>
              <TemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/role-management/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
