/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, FC, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'
//RoleName ../../../../app/modules/auth

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()
  var ListofPermission: any = currentUser?.PermissionsList
  var UserRole: any = currentUser

  var CallerProductivityDashboardIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "CallerProductivityDashboard");
  var AnalystProductivityDashboardIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "AnalystProductivityDashboard");
  var PracticeDashboardIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "PracticeDashboard");
  var UserManagementIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "UserManagement");
  var UploadClaimsIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "UploadClaims");
  var ViewClaimsIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "ViewClaims");
  var ReportPCAIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "ReportPCA");
  var ViewMyClaimsIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "ViewMyClaims");
  var AddNewPMIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "AddNewPM");
  var AddNewPracticeIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "AddNewPractice");

  var ViewSystemUsersIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "ViewSystemUsers");
  var ReportsIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "Reports");
  var WeeklyReportIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "WeeklyReport");
  var BotUtlizationIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "BotUtlization");
  var ProductionTrackIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "ProductionTrack");
  var UpdateProductionIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "UpdateProduction")
  var ValidateProductionIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "ValidateProduction")
  var BotStatusCheckClaimsIndex = ListofPermission.findIndex((getLabel: any) => getLabel.label == "BotStatusCheckClaims");
  return (

    <>
      {ListofPermission.length > 0 ?
        <>
          <SidebarMenuItemWithSub to='/error' title='Dashboards' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >

            {ListofPermission[CallerProductivityDashboardIndex].value ?

              <SidebarMenuItem to='/dashboard' icon='/media/icons/duotune/art/art002.svg' title={`Caller Productivity Dashboard`} fontIcon='bi-app-indicator' /> : null}

            {ListofPermission[AnalystProductivityDashboardIndex].value ?
              <SidebarMenuItem to='/AnalystProductivity' icon='/media/icons/duotune/art/art002.svg' title={"Analyst Productivity Dashboard"} fontIcon='bi-app-indicator' /> : null}

            {ListofPermission[PracticeDashboardIndex].value ?
              <SidebarMenuItem to='/Practicewise' icon='/media/icons/duotune/art/art002.svg' title={"Practice Dashboard"} fontIcon='bi-app-indicator' /> : null}

          </SidebarMenuItemWithSub>

          {ListofPermission[UserManagementIndex].value ?
            <SidebarMenuItemWithSub to='/crafted/pages' title='User Management' fontIcon='bi-archive' icon='/media/icons/duotune/general/gen022.svg' >

              <SidebarMenuItem to='/CreateUser' title='Manage User Account' hasBullet={true} />
              <SidebarMenuItem to='/UsersList' title='List Users' hasBullet={true} />
            </SidebarMenuItemWithSub> : null
          }
          {UserRole.RoleType == "admin" ?
            <SidebarMenuItemWithSub to='/error' title='Role Management' icon='/media/icons/duotune/communication/com006.svg' fontIcon='bi-person' >

              <SidebarMenuItem to='/CreateRole' title='Manage Restrictions' hasBullet={true} />
              <SidebarMenuItem to='/RolesList' title='List Roles' hasBullet={true} />
            </SidebarMenuItemWithSub>
            : null
          }

          <SidebarMenuItemWithSub to='/error' title='Sample Templates' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen005.svg' >

            <SidebarMenuItem to='/Template' title='Claim Based' hasBullet={true} />
            <SidebarMenuItem to='/LineBased' title='Line Based' hasBullet={true} />
          </SidebarMenuItemWithSub>



          <SidebarMenuItemWithSub to='/error' title='Claim Management' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen028.svg' >
            {ListofPermission[UploadClaimsIndex].value ?
              <SidebarMenuItem to='/UploadClaim/horizontal' title='Upload Claim' hasBullet={true} /> : null}
            {ListofPermission[ViewClaimsIndex].value ?
              <SidebarMenuItem to='/ViewClaim' title='View All Claims' hasBullet={true} /> : null}

            {ListofPermission[ReportPCAIndex].value ?
              <SidebarMenuItem to='/PCA' title='PCA' hasBullet={true} /> : null}

            {ListofPermission[ViewMyClaimsIndex].value ?
              <SidebarMenuItem to='/UploadClaim/botInput' title='Bot Input' hasBullet={true} /> : null}

            {ListofPermission[ViewMyClaimsIndex].value ?
              <SidebarMenuItem to='/ClaimCallerView' title='View My Claims' hasBullet={true} /> : null}

            {UserRole.RoleType === "AR-Caller" && ListofPermission[ViewMyClaimsIndex].value ?
              <SidebarMenuItem to='/CompletedClaims' title='View Completed Claims' hasBullet={true} /> : null}

            {ListofPermission[ViewClaimsIndex].value ?
              <SidebarMenuItem to='/Touched' title='Touched/UnTouched' hasBullet={true} /> : null}

            {ListofPermission[WeeklyReportIndex].value ?
              <SidebarMenuItem to='WeeklyReport' title='Weekly Report' hasBullet={true} />
              : null
            }
            
            <SidebarMenuItem to='ARCollectionbyUser' title='AR Collection By User' hasBullet={true} />
            
          </SidebarMenuItemWithSub>

          {ListofPermission[AddNewPMIndex].value || ListofPermission[AddNewPracticeIndex].value ?
            <SidebarMenuItemWithSub to='/error' title='System' fontIcon='bi-sticky' icon='/media/icons/duotune/files/fil001.svg' >
              {ListofPermission[AddNewPMIndex].value ?
                <SidebarMenuItem to='Pmsystem/users' title='PM System' hasBullet={true} /> : null}
              {ListofPermission[AddNewPracticeIndex].value ?
                <SidebarMenuItem to='Practice' title='Practice' hasBullet={true} /> : null}

            </SidebarMenuItemWithSub> : null}

          {UserRole.RoleType == "admin" ?
            <SidebarMenuItem to='/DumpClaimUpload' title='First Time Claims Upload' hasBullet={true} />
            // <SidebarMenuItem to='/DumpClaimUpload' title='First Time Claims Upload' hasBullet={true} />
            : null
          }

          {UserRole.RoleType == "admin" ?
            <SidebarMenuItem to='AddRootCauseStatus' title='Add RootCause / Status' hasBullet={true} />
            : null
          }
          {ListofPermission[ViewSystemUsersIndex].value ?
            <SidebarMenuItem to='ViewSystemUsers' title='View System Users' hasBullet={true} />
            : null
          }
          {ListofPermission[ReportsIndex].value ?
            <SidebarMenuItem to='Reports' title='Reports' hasBullet={true} />
            : null
          }
          {BotUtlizationIndex > 0  && ListofPermission[BotUtlizationIndex].value ?
            <SidebarMenuItem to='BotUtlization' title='Bot Utlization' hasBullet={true} />
            : null
          }
          {ProductionTrackIndex > 0 && ListofPermission[ProductionTrackIndex].value ?
            <SidebarMenuItem to='ProductionTrack' title='Production Track' hasBullet={true} />
            : null
          }
          {UpdateProductionIndex > 0 && ListofPermission[UpdateProductionIndex].value ?
            <SidebarMenuItem to='UpdateProduction' title='Update Production' hasBullet={true} />
            : null
          }
          {ValidateProductionIndex > 0 && ListofPermission[ValidateProductionIndex].value ?
            <SidebarMenuItem to='ValidateProduction' title='Validate Production' hasBullet={true} />
            : null
          }
          {ListofPermission[BotStatusCheckClaimsIndex].value ?
            <SidebarMenuItem to='BotStatusCheckClaims' title='Need To Check' hasBullet={true} />
          : null }
          {UserRole.RoleType == "admin" ?
            <SidebarMenuItem to='BotNeedToCheckReport' title='Bot Check Report' hasBullet={true} />
          : null }
          
        </> : null}
    </>


  )
}

export { SidebarMenuMain }
