import React, { useState, useEffect } from 'react'
import { DataGridPro, GridColDef, GridFilterModel, GridSortModel, GridRowParams, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridRowModel, getGridSingleSelectOperators, GridFilterItem, GridCellParams } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Fab } from '@mui/material';
import axios from "axios";
import Select from 'react-select';
import swal from 'sweetalert';
import { KTSVG } from '../../../../_metronic/helpers'
import { TableStyles } from "../common/TableStyle"
import { HideFooterButtons } from "../common/HideFooterButtons"
import { AgingOperators } from '../common/AgingOperator';
import { NeedToCheckOperator } from '../common/NeedToCheckOperator';
import { DateOperator } from "../common/DateOperator";
import { PriorityOperators } from '../common/PriorityOperator';
import { buttonBaseProps } from '../common/buttonBaseProp';
import moment from "moment-timezone"
import { useAuth } from '../../../../app/modules/auth'
import "../common/style.css";
const getClaimsNeedToCheck = `/api/NeedToCheck/getClaimsNeedToCheck`
const getClaimHistory = `/api/Claims/getClaimHistory`
const ClaimNeedtoUpdate = `/api/NeedToCheck/ClaimNeedtoUpdate`
const CCNUpdate = `/api/Claims/CCNUpdate`
const getPracticeList = `/api/practice/getPracticeListById`
const UpdateColumnsByUser = `/api/Claims/UpdateColumnsByUser`
const getColumnsByUser = `/api/Claims/getColumnsByUser`
const getGenerateClaimStatus = `/api/NeedToCheck/getGenerateClaimStatus`
const getPmList = `/api/pmsystem/getPmList`
var OptionForBcps = [
    { value: "Arizona", label: "" },
    { value: "California", label: "" },
    { value: "Florida", label: "" },
    { value: "Georgia", label: "" },
    { value: "Illinois", label: "" },
    { value: "Indiana", label: "" },
    { value: "New Mexico", label: "" },
    { value: "New York", label: "" },
    { value: "Texas", label: "" },
]

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const tagsFilterOperators = getGridSingleSelectOperators().filter((operator) => operator.value === "isAnyOf").map((operator) => {
    const newOperator = { ...operator }
    const newGetApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef) => {
        return (params: GridCellParams | any): boolean => {
            let isOk = true
            if (params.value == filterItem.value) {
                isOk = true
            }
            return isOk
        }
    }
    newOperator.getApplyFilterFn = newGetApplyFilterFn
    return newOperator
})
const BotStatusCheckClaims: React.FC = () => {

    const [selectedRows, setSelectedRows] = React.useState([]);
    const { currentUser }: any = useAuth()
    const [formData, setFormData] = useState({
        FromDate: '',
        ToDate: '',
    });
    const [showModal, setshowModal] = React.useState("");
    const [fromType, setfromType] = React.useState("");
    const [ClaimCurrentData, SetClaimCurrentData] = React.useState({ Bill: "" });
    const [ClaimHistoryData, setClaimHistoryData] = React.useState([]);
    const [GroupByStatusCodes, setGroupByStatusCodes] = React.useState(["CALL", "Auto Open"]);
    const [showModalClaimhistory, setshowModalClaimhistory] = React.useState("");
    const [SelectedPmSystem, setSelectedPmSystem] = useState({})
    const [SelectedPayerUserName, setSelectedPayerUserName] = useState({ value: "", label: "" })
    const [DefaultColumns, setDefaultColumns] = useState({ NeedToCheck: false, Bill: false, DateOfService: false, PatientName: false, PayerName: false, ClaimStatus: false, ClaimBilledAmount: false, ClaimPayment: false, ClaimBalance: false, Account: false, ProviderName: false, SystemStatus: false, Aging: false, DueDate: false, Comments: false, updatedAt: false, FirstName: false, Location: false, MemberId: false, PayerMix: false, IntialClaimDate: false, ClaimAdjustemnt: false, Stage: false, RoleResponsibilityCategory: false, DenialReason: false, ServiceType: false, RootCause: false, Modifier: false, PCAStatus: false, ProcedureCode: false, FacilityName: false, PayerResponsibility: false, Indication: false, ProcedureBalance: false, FiledStatus: false, PatientDOB: false, AdjustmentReason: false, CompanyName: false, OrginalICN: false, Diagnosis: false, SecondaryInsuranceCompany: false, DOE: false, Unit: false, ProcedureBilledAmount: false, ProcedurePayment: false, ProcedureAdjustment: false, Status: false, BotComments: false, BotStatusCheck: false, BotStatus: false, BotStatusUpdateDate: false })

    const [unselectedRows, setUnselectedRows] = useState([]); // Initially unselected rows
    const [SelectedPractice, setSelectedPractice] = useState([])
    const [PracticeListData, setPracticeListData] = useState([])
    const [SelectedIDs, setSelectedIDs] = useState([])
    const [AlreadySelectedIDs, setAlreadySelectedIDs] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
    const [togglePlusValue, setTogglePlusValue] = useState(false)
    const [toggleMinusValue, setToggleMinusValue] = useState(true)

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'updatedAt',
            sort: 'desc',
        },
    ]);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        totalClaimBalance: 0.00,
        pageSize: {
            page: 0,
            pageSize: 20
        }
        // page: 1,
        // pageSize: 20
    })
    const GetPmList = async () => {
        const getPmListData = await axios.get(getPmList)
        if (getPmListData.data) {
            setdatas(getPmListData.data.data)
        }
    }
    const handleExport = async (_e: any) => {
        var url = ""
        if (formData.FromDate && formData.ToDate) {
            var FilterModel: any = {
                items: [{
                    field: 'createdAt',
                    operator: 'Range',
                    value: `${formData.FromDate}Split${formData.ToDate}`
                }]
            }
            url = `${getClaimsNeedToCheck}?pmid=${JSON.stringify(SelectedPmSystem)}&practicelist=${JSON.stringify(SelectedPractice)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(FilterModel.items)}&type=download&sort=${JSON.stringify(sortModel)}&payer=${SelectedPayerUserName.value}`
        } else {
            url = `${getClaimsNeedToCheck}?pmid=${JSON.stringify(SelectedPmSystem)}&practicelist=${JSON.stringify(SelectedPractice)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=download&sort=${JSON.stringify(sortModel)}&payer=${SelectedPayerUserName.value}`
        }

        setLoading(true)
        axios.get(url).then((response) => {
            setLoading(false)
            const url = window.location.origin + "/" + response.data;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "");
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
    }
    const CustomToolBar = () => {

        return (<GridToolbarContainer>

            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {/* <Button
                {...buttonBaseProps}
                onClick={(e) => handleExport(e)}
            >Export</Button> */}
            <Button disabled={SelectedPayerUserName.value ? false : true} {...buttonBaseProps} onClick={(e) => handleGenerateClaimStatus(e)}>
                Generate Claim Status
            </Button>
        </GridToolbarContainer>
        );
    }
    var PmSystemVC: any;
    var PracticeNameVC: any;
    var PageVC: any;
    var PageSizeVC: any;
    var FilterModelVC: any;
    var SortModelVC: any;
    var DataPayerVC: any;
    const fetchData = async () => {

        if (SelectedPayerUserName.value) {

            sessionStorage.setItem("PageVC", JSON.stringify(pageState.pageSize.page))
            sessionStorage.setItem("PageSizeVC", JSON.stringify(pageState.pageSize.pageSize))
            sessionStorage.setItem("FilterModelVC", JSON.stringify(filterModel.items))
            sessionStorage.setItem("SortModelVC", JSON.stringify(sortModel))
            setPageState(old => ({ ...old, isLoading: true }))
            sessionStorage.setItem("payerVC", JSON.stringify({ label: SelectedPayerUserName.value, value: SelectedPayerUserName.value }))
            // set the session storage here
            var Result = await axios.get(`${getClaimsNeedToCheck}?pmid=${JSON.stringify(SelectedPmSystem)}&practicelist=${JSON.stringify(SelectedPractice)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=view&sort=${JSON.stringify(sortModel)}&payer=${SelectedPayerUserName.value}`)
            setPageState(old => ({ ...old, isLoading: false, data: Result.data.data, total: Result.data.total, totalClaimBalance: Result.data.totalClaimBalance }))
            setGroupByStatusCodes(Result.data.SystemStatus)
            if (Result.data.data.length > 0) {
                setSelectedIDs([])
                var GetSelectedIDs = Result.data.data.filter((x: any) => x.NeedToCheck === "Yes").map((x: any) => x._id);
                setSelectedIDs(GetSelectedIDs)
                setAlreadySelectedIDs(GetSelectedIDs)
            }
            onRowsSelectionHandler(SelectedIDs)
        }
    }
    const getSelectedColumns = async () => {
        const getUserColumns = await axios.get(`${getColumnsByUser}?PageType=BotStatusCheckClaims`)
        var getDefaultColumns = getUserColumns.data.data
        if (getUserColumns.data.data && Object.keys(getUserColumns.data.data).length > 0) {
            setDefaultColumns(getDefaultColumns)
        }
    }

    const FetchDataBack = async () => {
        if (sessionStorage.getItem("PmSystemVC")) {
            const SavedData: any = sessionStorage.getItem("PmSystemVC")
            PmSystemVC = JSON.parse(SavedData)

            const SavedDataPractice: any = sessionStorage.getItem("PracticeNameVC")
            PracticeNameVC = JSON.parse(SavedDataPractice)

            const SavedDataPage: any = sessionStorage.getItem("PageVC")
            PageVC = JSON.parse(SavedDataPage)
            const SavedDataPageSize: any = sessionStorage.getItem("PageSizeVC")
            PageSizeVC = JSON.parse(SavedDataPageSize)
            const SavedDataFilter: any = sessionStorage.getItem("FilterModelVC")
            FilterModelVC = JSON.parse(SavedDataFilter)
            const SavedDataSort: any = sessionStorage.getItem("SortModelVC")
            SortModelVC = JSON.parse(SavedDataSort)

            const SavedDatapayerVC: any = sessionStorage.getItem("payerVC")
            DataPayerVC = JSON.parse(SavedDatapayerVC)
            console.log("DataPayerVC FetchDataBack", SavedDataPractice, DataPayerVC)
            setSelectedPmSystem(PmSystemVC)
            setSelectedPractice(PracticeNameVC)
            if (DataPayerVC.value) {
                setSelectedPayerUserName(DataPayerVC)

                axios.post(getPracticeList, { PostData: PmSystemVC.value }).then((res) => {
                    setPracticeListData(res.data.data)
                })

                if (PracticeNameVC.length > 0) {

                    var Result = await axios.get(`${getClaimsNeedToCheck}?pmid=${JSON.stringify(PmSystemVC)}&practicelist=${JSON.stringify(PracticeNameVC)}&page=${PageVC}&limit=${PageSizeVC}&filter=${JSON.stringify(FilterModelVC)}&type=view&sort=${JSON.stringify(SortModelVC)}&payer=${DataPayerVC.value}`)
                    setPageState(old => ({ ...old, isLoading: false, data: Result.data.data, total: Result.data.total, totalClaimBalance: Result.data.totalClaimBalance }))
                    setGroupByStatusCodes(Result.data.SystemStatus)
                    if (Result.data.data.length > 0) {
                        setSelectedIDs([])
                        var GetSelectedIDs = Result.data.data.filter((x: any) => x.NeedToCheck === "Yes").map((x: any) => x._id);
                        setSelectedIDs(GetSelectedIDs)
                        setAlreadySelectedIDs(GetSelectedIDs)
                    }
                }
            }
        }
    }
    useEffect(() => {
        getSelectedColumns()
        GetPmList()
    }, [])
    const scrollToTop = () => {
      
    };
    useEffect(() => {
        fetchData()
    }, [pageState.pageSize.page, pageState.pageSize.pageSize, filterModel.items, sortModel])

    useEffect(() => {
        FetchDataBack()
    }, [])
    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow: any = { ...newRow, isNew: false };
        await axios.post(CCNUpdate, { ClaimId: updatedRow.id, CCN: updatedRow.CCN })
        return updatedRow;
    };

    const columns: GridColDef[] = [

        {
            align: 'center', headerAlign: "center", field: 'NeedToCheck', filterOperators: NeedToCheckOperator, type: "text", headerName: 'Need To Check', width: 80, editable: false,
        },
        {
            align: 'center', headerAlign: "center", field: 'Priority', filterOperators: PriorityOperators, type: "number", headerName: 'Priority', width: 80, editable: false, renderCell: ({ row }: Partial<GridRowParams>) =>
                <>{row.Priority === 1 ? <><KTSVG path='/media/icons/duotune/abstract/abs050.svg' className='svg-icon-1' /></> : <><KTSVG path='/media/icons/duotune/abstract/abs053.svg' className='svg-icon-1' /></>} </>
        },
        { align: 'center', headerAlign: "center", field: 'Bill', headerName: 'Claim#', width: 150, },
        { align: 'center', headerAlign: "center", field: 'CCN', headerName: 'CCN#', width: 150, editable: true, },
        { filterOperators: DateOperator, align: 'center', headerAlign: "center", field: 'DateOfService', headerName: 'Service Date', width: 150, type: "text", renderCell: ({ row }: Partial<GridRowParams>) => <>{moment(row.DateOfService).tz("America/New_York").format("MM-DD-YYYY")}</> },

        {
            align: 'center', headerAlign: "center", field: 'PatientName', headerName: 'Patient Name', width: 200
        },
        { align: 'center', headerAlign: "center", field: 'PayerName', headerName: 'Payer', width: 200, },
        { align: 'center', headerAlign: "center", field: 'ClaimStatus', headerName: 'Claim Status', width: 150, },
        { valueFormatter: ({ value }) => currencyFormatter.format(value), align: 'center', headerAlign: "center", field: 'ClaimBilledAmount', headerName: 'Charges ($)', width: 100, type: "number" },
        { valueFormatter: ({ value }) => currencyFormatter.format(value), align: 'center', headerAlign: "center", field: 'ClaimPayment', headerName: 'PMTS/ADJS ($)', width: 100, type: "number" },
        { valueFormatter: ({ value }) => currencyFormatter.format(value), align: 'center', headerAlign: "center", field: 'ClaimBalance', headerName: 'Balance ($)', width: 100, type: "number" },
        {
            align: 'center', headerAlign: "center", field: 'Account', headerName: 'Account Number', width: 100,
        },
        { align: 'center', headerAlign: "center", field: 'ProviderName', headerName: 'Provider Name', width: 200, },
        { align: 'center', headerAlign: "center", field: 'StatusLevel', headerName: 'Status Level', width: 200, },
        { align: 'center', headerAlign: "center", field: 'SPAssignment', headerName: 'SP Assignment', width: 200, },
        { align: 'center', headerAlign: "center", field: 'Exclusion', headerName: 'SP Exclusion', width: 200, },
        { filterOperators: DateOperator, align: 'center', headerAlign: "center", field: 'SPAssignmentDate', headerName: 'SP Assignment Date', width: 150, type: "text", renderCell: ({ row }: Partial<GridRowParams>) => <>{row.SPAssignmentDate ?  moment(row.SPAssignmentDate).format("MM-DD-YYYY") : null}</> },
        
        { align: 'center', headerAlign: "center", field: 'CheckNumber', headerName: 'Check #', width: 200, },
        { filterOperators: DateOperator, align: 'center', headerAlign: "center", field: 'CheckDate', headerName: 'Check Date', width: 150, type: "text", renderCell: ({ row }: Partial<GridRowParams>) => <>{row.CheckDate ?  moment(row.CheckDate).format("MM-DD-YYYY") : null}</> },
        // { align: 'center', headerAlign: "center", field: 'SystemStatus', headerName: 'Status', width: 200, },
        { align: 'center', headerAlign: "center", field: 'Status', headerName: 'Filter Status', width: 200, type: "singleSelect", valueOptions: GroupByStatusCodes, filterOperators: tagsFilterOperators },
        { align: 'center', headerAlign: "center", field: 'BotStatus', headerName: 'Bot Status', width: 200, },
        { align: 'center', headerAlign: "center", field: 'BotStatusUpdateDate', headerName: 'Bot Status Update Date', width: 200, },
        { align: 'center', headerAlign: "center", field: 'Location', headerName: 'Location', width: 200, },
        { align: 'center', headerAlign: "center", field: 'MemberId', headerName: 'Member ID', width: 200, },
        { align: 'center', headerAlign: "center", field: 'PayerMix', headerName: 'Payer Mix', width: 200, },
        { filterOperators: DateOperator, align: 'center', headerAlign: "center", field: 'IntialClaimDate', headerName: 'Intial Claim Date', width: 150, type: "text", renderCell: ({ row }: Partial<GridRowParams>) => <>{moment(row.IntialClaimDate).tz("America/New_York").format("MM-DD-YYYY")}</> },
        { align: 'center', headerAlign: "center", field: 'ClaimAdjustemnt', headerName: 'Adjustment ($)', width: 200, },
        { align: 'center', headerAlign: "center", field: 'Stage', headerName: 'Stage', width: 200, },
        { align: 'center', headerAlign: "center", field: 'RoleResponsibilityCategory', headerName: 'Role', width: 200, },
        { align: 'center', headerAlign: "center", field: 'DenialReason', headerName: 'DenialReason', width: 200, },
        { align: 'center', headerAlign: "center", field: 'ServiceType', headerName: 'ServiceType', width: 200, },
        { align: 'center', headerAlign: "center", field: 'RootCause', headerName: 'Root Cause', width: 200, },
        { align: 'center', headerAlign: "center", field: 'Modifier', headerName: 'Modifier', width: 200, },
        { align: 'center', headerAlign: "center", field: 'ProcedureCode', headerName: 'ProcedureCode', width: 200, },
        { align: 'center', headerAlign: "center", field: 'FacilityName', headerName: 'FacilityName', width: 200, },
        { align: 'center', headerAlign: "center", field: 'PayerResponsibility', headerName: 'PayerResponsibility', width: 200, },
        { align: 'center', headerAlign: "center", field: 'Indication', headerName: 'Indication', width: 200, },
        { align: 'center', headerAlign: "center", field: 'ProcedureBalance', headerName: 'ProcedureBalance ($)', width: 200, },
        { align: 'center', headerAlign: "center", field: 'FiledStatus', headerName: 'FiledStatus', width: 200, },
        { align: 'center', headerAlign: "center", field: 'PatientDOB', headerName: 'PatientDOB', width: 200, },
        { align: 'center', headerAlign: "center", field: 'AdjustmentReason', headerName: 'AdjustmentReason', width: 200, },
        { align: 'center', headerAlign: "center", field: 'CompanyName', headerName: 'CompanyName', width: 200, },
        { align: 'center', headerAlign: "center", field: 'OrginalICN', headerName: 'OrginalICN', width: 200, },
        { align: 'center', headerAlign: "center", field: 'Diagnosis', headerName: 'Diagnosis', width: 200, },
        { align: 'center', headerAlign: "center", field: 'SecondaryInsuranceCompany', headerName: 'SecondaryInsuranceCompany', width: 200, },
        { align: 'center', headerAlign: "center", field: 'DOE', headerName: 'DOE', width: 200, },
        { align: 'center', headerAlign: "center", field: 'Unit', headerName: 'Unit', width: 200, },
        { align: 'center', headerAlign: "center", field: 'ProcedureBilledAmount', headerName: 'ProcedureBilledAmount', width: 200, },
        { align: 'center', headerAlign: "center", field: 'ProcedurePayment', headerName: 'ProcedurePayment ($)', width: 200, },
        { align: 'center', headerAlign: "center", field: 'ProcedureAdjustment', headerName: 'ProcedureAdjustment ($)', width: 200, },
        {
            align: 'center', headerAlign: "center", field: 'Aging', filterOperators: AgingOperators, type: "number", headerName: 'Aging', width: 200,
        },
        { filterOperators: DateOperator, align: 'center', headerAlign: "center", field: 'DueDate', type: 'text', headerName: 'Due Date', width: 200, renderCell: ({ row }: Partial<GridRowParams>) => <>{row.DueDate ? moment(row.DueDate).tz("America/New_York").format("MM-DD-YYYY") : ""}</> },

        {
            align: 'center', headerAlign: "center", field: 'Comments', headerName: 'Comments', width: 200, renderCell: ({ row }: Partial<GridRowParams>) =>
                <a href="#" title={row.Comments} className='' onClick={(e) => {
                    e.preventDefault()
                    ViewClaimHistory(row, "No")
                }}>
                    {row.Comments}
                </a>,
        },
        {
            align: 'center', headerAlign: "center", field: 'BotComments', headerName: 'Bot Comments', width: 200, renderCell: ({ row }: Partial<GridRowParams>) =>
                <a href="#" title={row.BotComments} className='' onClick={() => ViewClaimHistory(row, "Yes")}>
                    {row.BotComments}
                </a>,
        },
        { align: 'center', headerAlign: "center", field: 'BotStatusCheck', headerName: 'BotStatusCheck', width: 200, },
        {
            filterOperators: DateOperator, align: 'center', headerAlign: "center", field: 'updatedAt', type: 'text', headerName: 'Last Worked Date', width: 200, renderCell: ({ row }: Partial<GridRowParams>) => <>{
                row.updatedAt ? moment(row.updatedAt).tz("America/New_York").format("MM-DD-YYYY") : ""}</>
        },
        {
            align: 'center', headerAlign: "center", field: 'AssignToName', headerName: 'Assign To', width: 200, renderCell: ({ row }: Partial<GridRowParams>) =>
                <>
                    {row.AssignToName}
                </>,
        },
        {
            align: 'center', headerAlign: "center", field: 'FirstName', headerName: 'Updated By', width: 200, renderCell: ({ row }: Partial<GridRowParams>) =>
                <>
                    {row.FirstName ? row.FirstName + " " + row.LastName : ""}
                </>,
        },
    ]


    const onRowsSelectionHandler = (newSelection: any) => {


        const selectedRowsData = newSelection.map((id: any) => pageState.data.find((row: any) => row.id === id));
        setSelectedRows(selectedRowsData)

        // Calculate the newly unselected rows by comparing with previous selection
        const newlyUnselectedRows = SelectedIDs.filter(id => !newSelection.includes(id));

        // Update the selection model with the new selection
        setSelectedIDs(newSelection);

        // Update the unselectedRows state by adding the new unselected rows to the existing state
        setUnselectedRows(prevUnselectedRows => [
            ...prevUnselectedRows,
            ...newlyUnselectedRows.filter(id => !prevUnselectedRows.includes(id)),
        ]);
    };
    const ViewClaimHistory = async (ids: any, Type: String) => {
        SetClaimCurrentData(ids)
        const Result = await axios.get(`${getClaimHistory}?ClaimId=${ids._id}`)
        if (ids.BotComments && currentUser.RoleType == "AR-Analyst" && ids.BotStatusCheck != "Yes") {
            const updatedDataa = { BotStatusCheck: "Yes" };
            setPageState((prevState: any) => {
                const updatedData = prevState.data.map((row: any) =>
                    row._id === ids._id ? { ...row, ...updatedDataa } : row
                );
                return { ...prevState, data: updatedData };
            });
        }
        if (Type === "Yes") {
            var GetBotCommentsOnly = Result.data.data.filter((x: { UserId: String }) => x.UserId === "65ffd9e31412e5b78ff2b15a")
            setClaimHistoryData(GetBotCommentsOnly)
        } else {
            var GetBotCommentsOnly = Result.data.data.filter((x: { UserId: String }) => x.UserId !== "65ffd9e31412e5b78ff2b15a")
            setClaimHistoryData(GetBotCommentsOnly)
        }

        setshowModalClaimhistory("show d-block")
        setfromType("View Claim History")
    };

    const CloseModal = (_event: any) => {
        setClaimHistoryData([])
        setshowModalClaimhistory("")
        setfromType("")
        setshowModal("")
        setfromType("")
    }
    const SubmitForm = () => {

        if (SelectedIDs.length > 0) {
            var PostData = {
                ClaimLines: JSON.stringify(SelectedIDs),
                unselectedRows: JSON.stringify(unselectedRows),
            }
            axios.post(ClaimNeedtoUpdate, PostData).then(_res => {
                swal({
                    title: 'Success',
                    icon: "success",
                    text: `Updated Successfully...`,
                })
                setUnselectedRows([])
                fetchData()
                setshowModal("")
                setfromType("")
            })
        }
    }
    const getPractice = async (event: any) => {
        sessionStorage.setItem("PracticeNameVC", JSON.stringify(event))
        if (Object.keys(event).length > 0) {
            setSelectedPractice(event)
        } else {
            setSelectedPractice([])
        }
    }
    const setColumnVisibilityModel = (e: any) => {
        setDefaultColumns(e)
        axios.post(UpdateColumnsByUser, { Columns: JSON.stringify([e]), PageType: "BotStatusCheckClaims" }).then((res) => {
        })
    }
    const getPMSystem = (event: any) => {
        setSelectedPmSystem(event)
        setSelectedPractice([])
        sessionStorage.setItem("PmSystemVC", JSON.stringify(event))
        sessionStorage.removeItem("PracticeNameVC")
        if (event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }
    }

    const getPayerName = async (event: any) => {
        sessionStorage.setItem("payerVC", JSON.stringify({ label: event.value, value: event.value }))
        setSelectedPayerUserName(event)
    };
    var PmListData = [{ label: "Select", value: "" }]
    getPmListData.map((name) => (
        PmListData.push({
            label: name.PmName,
            value: name._id
        })
    ))
    const handleChangePlus = (event: any) => {
        setTogglePlusValue(false)
        setToggleMinusValue(true)
    };
    const handleChangeMinus = (event: any) => {
        setTogglePlusValue(true)
        setToggleMinusValue(false)
    };

    const handleGenerateClaimStatus = async (_e: any) => {
        var url = ''
        const field1Value = SelectedPayerUserName.value;
        if (field1Value == "BCBS") {
            for (var i = 0; i < OptionForBcps.length; i++) {
                var ReadBcps: any = OptionForBcps[i]
                setLoading(true)
                await axios.get(`${getGenerateClaimStatus}?pmid=${JSON.stringify(SelectedPmSystem)}&practicelist=${JSON.stringify(SelectedPractice)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=download&sort=${JSON.stringify(sortModel)}&payer=${field1Value}&payerState=${ReadBcps.value}`).then((response) => {
                    setLoading(false)
                    const url = window.location.origin + '/' + response.data
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', '')
                    document.body.appendChild(link)
                    link.click()
                }).catch((error) => console.log(error))
            }
        } else {
            url = `${getGenerateClaimStatus}?pmid=${JSON.stringify(SelectedPmSystem)}&practicelist=${JSON.stringify(SelectedPractice)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=download&sort=${JSON.stringify(sortModel)}&payer=${field1Value}`
            setLoading(true)
            axios.get(url).then((response) => {
                setLoading(false)
                const url = window.location.origin + '/' + response.data
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', '')
                document.body.appendChild(link)
                link.click()
            }).catch((error) => console.log(error))
        }

    }

    return (
        <div >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="header header_section">
                <span className="section">Need To Check Status</span>
                <span className="arrow">&rsaquo;</span>
                <span className='section'></span>
            </div>
            <div className='card-header border-0 cursor-pointer' role='button' data-bs-toggle='collapse' data-bs-target='#kt_account_profile_details' aria-expanded='true' aria-controls='kt_account_profile_details' >
                <div className='card-title m-0'>
                    <span className={`fa fa-2x fa-plus-square float-end fw-bolder m-0 ${togglePlusValue ? 'plusshow' : 'plushide'}`} onClick={handleChangePlus}></span>
                    <span className={`fa fa-2x fa-minus-square float-end fw-bolder m-0 ${toggleMinusValue ? 'minusshow' : 'minushide'}`} onClick={handleChangeMinus}></span>
                </div>
            </div>
            <hr />

            <div id="kt_account_profile_details" className='row col-12 collapse show'>
                <div className='accordion' id='kt_accordion_1'>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                            <button className='accordion-button fs-5 fw-bold collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#kt_accordion_1_body_1' aria-expanded='false' aria-controls='kt_accordion_1_body_1' >
                                Choose PM System / Practice Name
                            </button>
                        </h2>
                        <div id='kt_accordion_1_body_1' className='accordion-collapse collapse show' aria-labelledby='kt_accordion_1_header_1' data-bs-parent='#kt_accordion_1'>
                            <div className='accordion-body'>
                                <div className='row'>
                                    <div className='col-sm-3 fv-row'>
                                        <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                            <span className='required'>PM System</span>
                                        </label><br />
                                        <Select maxMenuHeight={180} value={
                                            SelectedPmSystem
                                        } onChange={getPMSystem} id="PmSystem" name={"PmSystem"} options={PmListData} />
                                    </div>
                                    <div className='col-sm-3 fv-row'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                            <span className='required'>Practice Name</span>
                                        </label>
                                        <Select maxMenuHeight={180} value={SelectedPractice} isMulti onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />
                                    </div>
                                    <div className='col-sm-3 fv-row'>
                                        <label className='col-form-label fw-bold fs-6'>
                                            <span className='required'>Payer List</span>
                                        </label>
                                        <Select maxMenuHeight={180} value={SelectedPayerUserName} onChange={getPayerName} id="UserName" name={"UserName"} options={[{ value: "UHC", label: "UHC" }, { value: "Aetna", label: "Aetna" }, { value: "Humana", label: "Humana" }, { value: "BCBS", label: "BCBS" },{value:"AMERICHOICE",label: "Ameri Choice"},{value:"EMPIRE ",label: "Empire BC Medicaid Health"}]} />
                                    </div>
                                    <div className='col-3 pt-13'>
                                        <button onClick={() => fetchData()} id="updatecomment" className='btn btn-primary'>{pageState.isLoading ? "Loading" : "Submit"} </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='row col-12 testnone align-items-end'>
                <div className='col-sm-2 fv-row'>
                    <button disabled={selectedRows.length > 0 ? false : true} onClick={() => SubmitForm()} id="updatecomment" className='btn btn-primary'>Update</button>
                </div>
            </div> */}
            <div style={{ width: "100%", }} className="pt-5">
                <DataGridPro
                    sx={TableStyles}
                    rowHeight={40}
                    componentsProps={HideFooterButtons}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(ids: any) => onRowsSelectionHandler(ids)}
                    processRowUpdate={processRowUpdate}
                    autoHeight={true}
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pageSizeOptions={[20, 40, 60, 80, 100]}
                    paginationModel={{
                        page: pageState.pageSize.page == 0 ? pageState.pageSize.page : pageState.pageSize.page,
                        pageSize: pageState.pageSize.pageSize
                    }}
                    paginationMode="server"
                    onPaginationModelChange={(newPageSize: any) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                    pagination
                    columns={columns}
                    density="compact"
                    filterModel={filterModel}
                    onColumnVisibilityModelChange={(newModel: any) => setColumnVisibilityModel(newModel)}
                    onSortModelChange={(model: any) => setSortModel(model)}
                    columnVisibilityModel={DefaultColumns}
                    components={{ Toolbar: CustomToolBar }}
                    rowSelectionModel={SelectedIDs}
                    sortModel={sortModel}
                    onFilterModelChange={(newFilterModel: React.SetStateAction<GridFilterModel>) => setFilterModel(newFilterModel)}
                />
            </div>
            <div className={`modal fade ${showModalClaimhistory}`} id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='false' >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>

                            <h3 className='fw-bolder'>{fromType}</h3>
                            <b>Claim No : {ClaimCurrentData.Bill}</b>
                            <div onClick={CloseModal} className='btn btn-icon btn-sm btn-active-icon-primary' data-kt-users-modal-action='close' style={{ cursor: 'pointer' }}>
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                        </div>
                        <div className='modal-body scroll-y'>

                            <table id='kt_table_users' className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                <thead>
                                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                        <th>Status</th>
                                        <th>Status Level</th>
                                        <th>Due Date</th>
                                        <th>Comments</th>
                                        <th>Created Date</th>
                                        <th>User</th>
                                    </tr>

                                </thead>
                                <tbody className='text-gray-600 fw-bold' >
                                    {
                                        ClaimHistoryData.map((res: any, _inde: any) => {
                                            return (
                                                <tr>
                                                    <td >{res.StatusCode}</td>
                                                    <td >{res.StatusLevel}</td>
                                                    <td >{res.DueDate ? moment(res.DueDate).tz("America/New_York").format("MM-DD-YYYY") : ""}</td>
                                                    <td className='text-wrap w-50'>{res.Comments}</td>
                                                    <td >{moment(res.createdAt).tz("America/New_York").format("MM-DD-YYYY")}</td>
                                                    <td>{res.FirstName}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Fab disabled={selectedRows.length > 0 ? false : true} onClick={SubmitForm} color="primary" aria-label="scroll to top" style={{ position: 'fixed', bottom: '12rem', right: '2rem' }} >
                Update
            </Fab>
            {showModal ? <div className='modal-backdrop fade show'></div> : null}
        </div>
    )
}
export { BotStatusCheckClaims }