import React from "react";


const thead: React.CSSProperties = {
  color: "#fff",
  backgroundColor: "#002d72",
  top: 70,
  position: 'sticky',
};

const Back: React.CSSProperties = {
  background: `linear-gradient(90deg, rgba(232,217,207,1) 17%, rgba(170,208,194,1) 100%, rgba(193,100,82,1) 100%)`,

};

type GrandTotalType = {
  data:any,
  total:{
    totalAmount0:number
    totalCount0:number
    totalAmount60:number
    totalCount60:number
    totalAmount120:number
    totalCount120:number
    
  },
  title:string
  }
 
const options = { style: 'currency', currency: 'USD' };
const TableRows = ({ data,total,title }:GrandTotalType) => {

  return (
    <>
      <div className='col-12 d-flex flex-column ' style={Back}>

        <h3 className="text-center mb-6 mt-4">{title}</h3>
        <table
          id='kt_table_users'
          className=' table-bordered align-middle fs-6 p-4  font-family-base no-footer w-full '
        >
          <thead style={thead}>
            <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
              <th className='px-3 py-2'></th>
              <th className='text-center p-2' colSpan={2} style={thead}>0-60</th>
              <th className='text-center p-2' colSpan={2} style={thead}>60-120</th>
              <th className='text-center p-2' colSpan={2} style={thead}>120 and more</th>
              <th className='text-center p-2' colSpan={2} style={thead}>Total</th>
            </tr>
            <tr>
              <th className="text-center p-2">Status Wise</th>

              <th className="text-center p-2">No of Claims</th>
              <th className="text-center p-2">Claim Balance</th>

              <th className="text-center p-2">No of Claims</th>
              <th className="text-center p-2">Claim Balance</th>

              <th className="text-center p-2">No of Claims</th>
              <th className="text-center p-2">Claim Balance</th>

              <th className="text-center p-2">No of Claims</th>
              <th className="text-center p-2">Claim Balance</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-medium p-8'>
            {data?.map((res: any, _inde: any) => {
              return (
                <tr className='py-10 px-4'>
                  <td className='px-3 py-2'>{res._id ? res._id : "None"}</td>
                  <td className='px-3 py-2'>{res.Count0 ? res.Count0 : "-"}</td>
                  <td className='px-3 py-2'>
                    {res.TotalAmount0 ? res.TotalAmount0.toLocaleString('en-US', options) : "-"}
                  </td>

                  <td className='px-3 py-2'>{res.Count60 ? res.Count60 : "-"}</td>
                  <td className='px-3 py-2'>
                    {res.TotalAmount60 ? res.TotalAmount60.toLocaleString('en-US', options) : "-"}
                  </td>

                  <td className='px-3 py-2'>{res.Count120 ? res.Count120 : "-"}</td>
                  <td className='px-3 py-2'>
                    {res.TotalAmount120 ? res.TotalAmount120.toLocaleString('en-US', options) : "-"}
                  </td>

                  <td className='px-3 py-2'>{(res.TotalCount ? res.TotalCount : 0)
                  }</td>

                  <td className='px-3 py-2'>
                    {(res.TotalTotalAmount ? res.TotalTotalAmount : 0)
                      .toLocaleString('en-US', options)}
                  </td>

                </tr>



              )
            })}
          </tbody>
          <thead>
            <tr>
              <th className='px-3 py-2'>Grand Total</th>
              <th className='px-3 py-2'>{(total.totalCount0 ? total.totalCount0 : "-")}</th>

              <th className='px-3 py-2'>{(total.totalAmount0 ? total.totalAmount0.toLocaleString('en-US', options) : "-")}</th>

              <th className='px-3 py-2'>{(total.totalCount60 ? total.totalCount60 : "-")}</th>
              <th className='px-3 py-2'>{(total.totalAmount60 ? total.totalAmount60.toLocaleString('en-US', options) : "-")}</th>
              <th className='px-3 py-2'>{(total.totalCount120 ? total.totalCount120 : "-")}</th>
              <th className='px-3 py-2'>{(total.totalAmount120 ? total.totalAmount120.toLocaleString('en-US', options) : "-")}</th>
              <th className='px-3 py-2'>{((total.totalCount0  ?total.totalCount0  :0 ) + (total.totalCount60 ? total.totalCount60 :0) +(total.totalCount120 ? total.totalCount120 :0)) }</th>


              <th className='px-3 py-2'>
                                             ${Number((total.totalAmount0 ? total.totalAmount0 : 0)
                                                  + (total.totalAmount60 ? total.totalAmount60 : 0)
                                                  + (total.totalAmount120 ? total.totalAmount120 : 0)).toFixed(2)}
                                          </th>

            </tr>
          </thead>
        </table>
      </div>
    </>
  );
};


export default TableRows
