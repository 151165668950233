import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select, { SingleValue } from 'react-select';
import { useDropzone } from 'react-dropzone';
import moment from 'moment-timezone';
import { KTSVG } from '../../../../_metronic/helpers';
import { useAuth } from '../../auth';
import Backdrop from '@mui/material/Backdrop';
import swal from 'sweetalert';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import "../common/style.css";
import AddTask from "./AddTask";

// API endpoints
const getUserList = `/users/getUsersList`;
const getPracticeList = `/api/practice/getPracticeListById`;
const getColumnsByUser = `/api/Claims/getColumnsByUser`;
const getPmList = `/api/pmsystem/getPmList`;
const GetTaskList = `/api/ProductionTrack/GetTaskList`;
const GerUsersProduction = `/api/ProductionTrack/GerUsersProduction`;
const SaveProduction = `api/ProductionTrack/SaveProduction`;
const DownlaodExcelAnalystWiseProduction = `api/AnalystProductivity/DownlaodExcelAnalystWiseProduction`
const ConsolidatedProductivity = `api/ConsolidatedProductivity`

interface ProdValues { FileNameTextError: String, FileName: String, WorkingHoursTextError: String, PCAEfficiency: String, Efficiency: String, PCA: String, Count: String, Payments: String, ProofNeed: String, label: String, value: String, ProcessName: String, WorkingHours: number; TargetUnit: string; Target: number }
interface FormValues {
    FromDate: string;
    WorkingStatus: string;
    SelectedTaskList: any;
}
function bytesForHuman(bytes: any) {
    let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

    let i = 0

    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }

    return bytes.toFixed(1) + ' ' + units[i]
}
const UpdateProduction: React.FC = () => {
    const today = new Date();
    const maxDate = today.toISOString().split('T')[0];
    const minDate = new Date(today.setDate(today.getDate() - 3)).toISOString().split('T')[0];
    const { currentUser }: any = useAuth()
    const [Files, setFiles] = useState<any[]>([]);
    const [ProviderEditData, setProviderEditData] = useState<any[]>([]);
    const [SelectedPmSystem, setSelectedPmSystem] = useState<SingleValue<{ label: string; value: string }>>({ label: 'Select', value: '' });
    const [SelectedPractice, setSelectedPractice] = useState<SingleValue<{ label: string; value: string }>>({ label: 'Select', value: '' });
    const [SelectedTaskList, setSelectedTaskList] = useState<any[]>([]);
    const [PracticeListData, setPracticeListData] = useState<any[]>([]);
    const [UsersProductionData, setUsersProductionData] = useState<any[]>([]);
    const [UsersPracticeProduction, setUsersPracticeProduction] = useState<any>({ OthersProduction: 0, TotalCount: 0, TotalEfficiency: 0, FinalPCACount: 0, Count: 0, PCA: 0, Efficiency: 0, PCAEfficiency: 0,FilePath: "",AttachmentName:""});
    const [TaskListData, setTaskListData] = useState<any[]>([]);
    const [FinalArray, setFinalArray] = useState<any[]>([]);
    const [getPmListData, setPmListData] = useState<any[]>([{ PmName: "", _id: "" }]);
    const [open, setOpen] = useState(false);
    const [ApprovalStatus, SetApprovalStatus] = useState("");
    const [RadioChecked, setRadioChecked] = useState<{ StatusActive: boolean; StatusInactive: boolean }>({
        StatusActive: false,
        StatusInactive: false
    });
    const [formData, setFormData] = useState<any>({
        WorkingStatus: "",
        FromDate: "",
    });
    const [FinalProviderArray, setFinalProviderArray] = useState<any[]>([]);

    const [CurrentSelectedData, setCurrentSelectedData] = useState<any>({
        UserProductionId : "",
        UserName: "",
        FromDate: "",
        UserId: "",
        RoleName: "",
        ApprovalStatus : "Pending",
        PracticeId  : "",
        WorkingDate : "",
        AttachmentName :"",
    });

    useEffect(() => {
        const GetPmList = async () => {
            const getPmListData = await axios.get(getPmList);
            if (getPmListData.data) {
                setPmListData(getPmListData.data.data);
            }
        };
        GetPmList();
    }, []);
    const getPMSystem = (event: SingleValue<{ label: string; value: string }>) => {
        setSelectedPmSystem(event);
        if (event && event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                if (res.data.data) {
                    const data = res.data.data;
                    var PracticeData = [{ label: 'Select', value: '' }];
                    data.map((GetRes: any, ind: number) => {
                        PracticeData.push({ label: GetRes.label, value: GetRes._id });
                    });
                    setPracticeListData(PracticeData);
                }
            }).catch((e) => {
                console.log(e);
            });
        } else {
            setSelectedPractice({ label: 'Select', value: '' });
            setPracticeListData([]);
        }
    };
    const getPractice = (event: SingleValue<{ label: string; value: string }>) => {
        if (event && event.value) {
            setSelectedPractice(event);
            setOpen(true);
            axios.get(GetTaskList, {
                params: {
                    PracticeId: event.value
                }
            }).then((res) => {
                setOpen(false);
                setTaskListData([]);
                var Data = [{ label: 'All', value: 'All', Target: "", TargetUnit: "", ProofNeed: "", ProcessName: "" }];
                res.data.Data.map((GetRes: any, ind: number) => {
                    Data.push({
                        label: GetRes.ProcessName,
                        value: GetRes.Task_id,
                        Target: GetRes.Target,
                        TargetUnit: GetRes.TargetUnit,
                        ProofNeed: GetRes.ProofNeed,
                        ProcessName: GetRes.ProcessName,
                    });
                });
                setFinalProviderArray(res.data.Data);
                setTaskListData(Data);
            });
        } else {
            setSelectedPractice({ label: 'Select', value: '' });
        }
    };
    const formatDate = (dateString: Date, format = "MMMM D, YYYY") => {
        const date = new Date(dateString);

        switch (format) {
            case "YYYY-MM-DD":
                return date.toISOString().split("T")[0];
            case "MMMM D, YYYY":
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            case "MM/DD/YYYY":
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
            case "DD-MM-YYYY":
                return date.toISOString().split("T")[0];
            case "YYYY_MM_DD":
                return date.toISOString().split("T")[0];
            default:
                return date.toLocaleDateString();
        }
    };
    const handleInputChange = async (event: any) => {
        setRadioChecked({ StatusActive: false, StatusInactive: false });
        const { name, value } = event.target;
        var PMName: any = SelectedPmSystem;
        var GetPracticeName: any = SelectedPractice;

        var formDataPost = {
            "PmId": PMName.value,
            "PracticeId": GetPracticeName.value,
            "Date": value
        };
        setFormData((prevState: any) => {
            return {
                ...prevState,
                [name]: value
            }
        });
        setFiles([])
        setOpen(true);
        const response = await axios.post(GerUsersProduction, formDataPost)
        setOpen(false);
        if (response.data.Data.length > 0) {  
            
            if(response.data.Data[0].ApprovalStatus == "Approved"){
                SetApprovalStatus(response.data.Data[0].ApprovalStatus)
            }else {
                SetApprovalStatus("")
            }
            if(response.data.Data[0].WorkingStatus == "No") {
                SetApprovalStatus("Approved")
            }
            if (response.data.Data[0].WorkingStatus === "Yes") {
                await UpdateUsersProduction(value)
                setRadioChecked({ StatusActive: true, StatusInactive: false });
            } else {
                setRadioChecked({ StatusActive: false, StatusInactive: true });
            }

            if (response.data.Data[0].WorkingTaskList.length > 0) {
                setFinalArray(response.data.Data[0].WorkingTaskList)
                setUsersPracticeProduction((prevState: any) => {
                    return {
                        ...prevState,
                        OthersProduction: response.data.Data[0].WorkingTaskList[0].OthersProduction,
                        FilePath : response.data.Data[0].FilePath,
                        AttachmentName : response.data.Data[0].AttachmentName
                    }
                });
                setCurrentSelectedData({
                    UserId :response.data.Data[0].UserId,
                    AttachmentName :response.data.Data[0].AttachmentName,
                    PracticeId  : response.data.Data[0].WorkingTaskList[0].PracticeId,
                    WorkingDate : formatDate(response.data.Data[0].WorkingDate,"YYYY_MM_DD")
                })
                setFormData((prevState: any) => {
                    return { ...prevState, WorkingStatus: response.data.Data[0].WorkingStatus }
                });
                var ListOfIDs: any = []
                response.data.Data[0].WorkingTaskList.map((GetTaskIds: any, idd: any) => {
                    ListOfIDs.push(GetTaskIds.TaskId)
                })
                var event: any = TaskListData.filter((x: any) => ListOfIDs.includes(x.value))
                console.log("TaskListData", event)
                setSelectedTaskList(event)
            }
        }else {
            SetApprovalStatus("")
            setFiles([])
            setFinalArray([])
            setSelectedTaskList([])
            setCurrentSelectedData({
                UserId : "",
                AttachmentName : "",
                PracticeId  : "",
                WorkingDate : ""
            })
        }

    };
    async function UpdateUsersProduction(value: String) {
        if (value) {
            var GetPracticeName: any = SelectedPractice;
            var Count = ""
            var PCA = ""
            var Efficiency = ""
            var PCAEfficiency = ""
            if (currentUser.RoleType == "AR-Caller") {
                setOpen(true);
                var Result = await axios.get(`${ConsolidatedProductivity}?UsersList=${JSON.stringify([{ label: currentUser._id, value: currentUser._id }])}&type=view&StartDate=${value}&ToDate=${value}&page=${0}&limit=${100}&filter=${JSON.stringify([])}&sort=${JSON.stringify([])}`)
                setOpen(false);
                setUsersProductionData(Result.data.data)
                if (Result.data.data.length > 0) {
                    var GetProduction = Result.data.data[0].SubData.filter((x: any) => x.practice_names == GetPracticeName?.label)

                    if (GetProduction.length > 0) {
                        Count = GetProduction[0].Total
                        Efficiency = GetProduction[0].Efficiency
                    }
                    setUsersPracticeProduction((prevState: any) => {
                        return {
                            ...prevState,
                            TotalCount: Result.data.data[0].TotalCalled,
                            TotalEfficiency: Result.data.data[0].Efficiency,
                            Count: Count,
                            Efficiency: Efficiency,
                        }
                    });
                }
                
            } else {
                setOpen(true);
                var Result = await axios.get(`${DownlaodExcelAnalystWiseProduction}?UsersList=${JSON.stringify([{ label: currentUser._id, value: currentUser._id }])}&type=view&StartDate=${value}&ToDate=${value}&page=${0}&limit=${100}&filter=${JSON.stringify([])}&sort=${JSON.stringify([])}`)
                setOpen(false);
                console.log("Result", Result)
                setUsersProductionData(Result.data.data)


                if (Result.data.data.length > 0) {
                    var GetProduction = Result.data.data[0].SubData.filter((x: any) => x.practice_names == GetPracticeName?.label)

                    if (GetProduction.length > 0) {
                        Count = GetProduction[0].Total
                        Efficiency = GetProduction[0].Efficiency
                        PCA = GetProduction[0].PCA ? GetProduction[0].PCA : 0
                        PCAEfficiency = GetProduction[0].PCAPer ? GetProduction[0].PCAPer : 0
                    }
                    setUsersPracticeProduction((prevState: any) => {
                        return {
                            ...prevState,
                            TotalCount: Result.data.data[0].TotalCalled,
                            TotalEfficiency: Result.data.data[0].Efficiency,
                            FinalPCACount: Result.data.data[0].FinalPCACount,
                            Count: Count,
                            PCA: PCA,
                            Efficiency: Efficiency,
                        }
                    });
                }
            }
        }
    }
    const handleWorkingStatus = async (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.value === 'Yes') {
            await UpdateUsersProduction(formData.FromDate)
            setRadioChecked({ StatusActive: true, StatusInactive: false });
        } else if (e.target.value === 'No') {
            setRadioChecked({ StatusActive: false, StatusInactive: true });
            setSelectedTaskList([]);
            setFinalArray([]);

        }
        setFormData((prevState: any) => {
            return { ...prevState, WorkingStatus: e.target.value }
        });
    };

    const OnChangeTask = (event: any) => {

        let AllSelect: String = "";
        event.map((GetAll: any, inde: any) => {
            if (GetAll.value == "All") {
                AllSelect = "Yes"
            }
        })
        if (AllSelect == "Yes") {
            event = TaskListData.filter((x: any) => x.value !== "All")
        }
        setSelectedTaskList(event)
        let sum1: number = 0;
        let GetProductionTotal: Array<number> = [];
        var updatedArray: any = [];
        // if(FinalArray.length  == 0){
        //     setFinalArray(event)
        // }else {
        event.map((GetEvent: any, inds: number) => {
            var GetMatchingids = FinalArray.filter((x: any) => x.ProcessName == GetEvent.ProcessName)
            if (GetMatchingids.length > 0) {
                if (GetMatchingids[0].WorkingHours) {
                    let value: number;
                    if (GetMatchingids[0].TargetUnit === "Nos") {
                        value = (GetMatchingids[0].WorkingHours / GetMatchingids[0].Target) * 100;
                    } else {
                        value = (100 / 480) * GetMatchingids[0].WorkingHours;
                    }
                    GetProductionTotal.push(Number(value.toFixed(2)));
                }
                updatedArray.push({ ...GetEvent, ...GetMatchingids[0] })
            } else {
                GetProductionTotal.push(0);
                updatedArray.push({
                    ...GetEvent, ...{
                        WorkingHours: "",
                        WorkingHoursTextError: "",
                        FileNameTextError: "",
                        FileName: ""
                    }
                })
            }
        })
        // FinalArray.map((Prod: ProdValues , ind: number) => {
        //     var GetMatchingids = event.filter((x:any)=> x.ProcessName == Prod.ProcessName)
        //     console.log("GetMatchingids",GetMatchingids)
        //     if(GetMatchingids.length > 0){
        //         if (Prod.WorkingHours) {
        //             let value: number;
        //             if (Prod.TargetUnit === "Nos") {
        //                 value = (Prod.WorkingHours / Prod.Target) * 100;
        //             } else {
        //                 value = (100 / 480) * Prod.WorkingHours;
        //             }
        //             GetProductionTotal.push(Number(value.toFixed(2)));
        //         }
        //         updatedArray.push(Prod)
        //     }
        // });
        setFinalArray(updatedArray)
        // }

        for (let i = 0; i < GetProductionTotal.length; i++) {
            sum1 += GetProductionTotal[i];
        }
        if (GetProductionTotal.length > 0) {
            setUsersPracticeProduction((prevState: any) => {
                return {
                    ...prevState,
                    OthersProduction: Number(sum1.toFixed(2)),
                }
            });
        }
    };
    const DownloadFile = async (e:any)=>{
        e.preventDefault();
        setOpen(true)
        
        await axios.get(e.target.href,{responseType: 'arraybuffer',}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${CurrentSelectedData.AttachmentName}`);
            setOpen(false);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
        return false;
    }
    const onDrop = useCallback((acceptedFiles: any[]) => {
        var filestotal = acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        setFiles(filestotal)
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'application/zip': ['.zip'],
            'application/x-zip-compressed': ['.zip']
        }
    });

    const acceptedFileItems = acceptedFiles.map((file: any) => {
        return (<li key={file.path}>
            {file.path} - {bytesForHuman(file.size)}
        </li>);
    });

    const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
        var PMName = SelectedPmSystem;
        var GetPracticeName = SelectedPractice;
        if (!PMName || !PMName.value) {
            swal({
                title: '',
                icon: 'info',
                text: 'Please Choose the PM Name'
            });
            return false;
        }
        if (!GetPracticeName || !GetPracticeName.value) {
            swal({
                title: '',
                icon: 'info',
                text: 'Please Choose the Practice Name'
            });
            return false;
        }
        if (!formData.FromDate) {
            swal({
                title: '',
                icon: 'info',
                text: 'Please Choose Date'
            });
            return false;
        }
        if (!formData.WorkingStatus) {
            swal({
                title: '',
                icon: 'info',
                text: 'Please Choose Working Status'
            });
            return false;
        }
        if (formData.WorkingStatus == "Yes") {
            if (SelectedTaskList.length === 0) {
                swal({
                    title: '',
                    icon: 'info',
                    text: 'Please Add Tasks'
                });
                return false;
            }
        }
        var formDataPost = new FormData();
        formDataPost.append("PmId", PMName.value);
        formDataPost.append("PracticeId", GetPracticeName.value);
        formDataPost.append("Date", formData.FromDate);
        formDataPost.append("WorkingStatus", formData.WorkingStatus);
        formDataPost.append("TotalCount", UsersPracticeProduction.TotalCount);
        formDataPost.append("TotalEfficiency", UsersPracticeProduction.TotalEfficiency);
        formDataPost.append("OthersProduction", UsersPracticeProduction.OthersProduction); // Others Production
        formDataPost.append("FinalArray", FinalArray.length > 0 ? JSON.stringify(FinalArray) : '');

        Object.entries(Files).forEach(([key, value]) => {
            formDataPost.append("ClaimFiles", value);
        });
        setOpen(true);
        const response = await axios.post(SaveProduction, formDataPost)
        setOpen(false);
        setFinalArray([])
        setSelectedTaskList([])
        setFiles([])
        swal({
            title: '',
            icon: "success",
            text: `${response.data.Response}`,
        }).then((_res: any) => {

        })
    };

    const handleUpdateFileName = (event: React.ChangeEvent<HTMLInputElement>, res: any) => {
        const { value, name, dataset, } = event.target;
        var dataError: any = dataset
        var dataErrorField = dataError.errorfield
        var updatedArray: any = [];
        SelectedTaskList.map((item: any, index) => {
            if (item.value === res.value) {
                const ErrorList: { [key: string]: string } = {
                    [dataErrorField]: value ? "" : dataError.error
                };
                Object.assign(item, {
                    [name]: value,
                    ...ErrorList
                });
            }
            updatedArray.push(item)
            if (index == SelectedTaskList.length - 1) {
                setFinalArray(updatedArray);
            }
        });
    };

    const handleUpdateProduction = (event: React.ChangeEvent<HTMLInputElement>, res: any) => {
        const { value, name, dataset, } = event.target;
        var dataError: any = dataset
        var dataErrorField = dataError.errorfield
        var updatedArray: any = [];
        var GetPracticeName = SelectedPractice;
        var Count = ""
        var PCA = ""
        var Efficiency = ""
        var PCAEfficiency = ""

        if (UsersProductionData.length > 0) {
            var GetProductionData: any = UsersProductionData
            var GetProduction = GetProductionData[0].SubData.filter((x: any) => x.practice_names == GetPracticeName?.label)

            if (GetProduction.length > 0) {
                Count = GetProduction[0].Total
                Efficiency = GetProduction[0].Efficiency
                PCA = GetProduction[0].PCA ? GetProduction[0].PCA : 0
                PCAEfficiency = GetProduction[0].PCAPer ? GetProduction[0].PCAPer : 0
            }
        }
        if (name == "WorkingHours") {
            SelectedTaskList.map((item: any, index) => {
                if (item.ProcessName === res.ProcessName) {
                    const ErrorList: { [key: string]: string } = {
                        [dataErrorField]: value ? "" : dataError.error
                    };
                    Object.assign(item, {
                        [name]: value,
                        Count,
                        PCA,
                        Efficiency,
                        PCAEfficiency,
                        ...ErrorList
                    });
                } else {
                    var GetMatchingids = FinalArray.filter((x: any) => x.ProcessName == item.ProcessName)
                    if (GetMatchingids.length > 0) {
                        if (res.ProcessName !== GetMatchingids[0].ProcessName) {
                            if (GetMatchingids[0].WorkingHours) {
                                Object.assign(item, {
                                    // WorkingHours: GetMatchingids[0].WorkingHours,
                                    // FileName: GetMatchingids[0].FileName,
                                    // FileNameTextError: GetMatchingids[0].FileNameTextError,
                                    // WorkingHoursTextError: GetMatchingids[0].WorkingHoursTextError,
                                    ...GetMatchingids[0],
                                    Count,
                                    PCA,
                                    Efficiency,
                                    PCAEfficiency,
                                });
                            }
                        }
                    }
                }
                updatedArray.push(item)
                if (index == SelectedTaskList.length - 1) {

                    let GetProductionTotal: Array<number> = [];
                    updatedArray.map((Prod: { WorkingHours: number; TargetUnit: string; Target: number }, ind: number) => {
                        if (Prod.WorkingHours) {
                            let value: number;
                            if (Prod.TargetUnit === "Nos") {
                                value = (Prod.WorkingHours / Prod.Target) * 100;
                            } else {
                                value = (100 / 480) * Prod.WorkingHours;
                            }
                            GetProductionTotal.push(Number(value.toFixed(2)));
                        }
                    });

                    let sum1: number = 0;
                    for (let i = 0; i < GetProductionTotal.length; i++) {
                        sum1 += GetProductionTotal[i];
                    }
                    setUsersPracticeProduction((prevState: any) => {
                        return {
                            ...prevState,
                            OthersProduction: Number(sum1.toFixed(2)),
                            Count,
                            PCA,
                            Efficiency,
                            PCAEfficiency,
                        }
                    });
                    setFinalArray(updatedArray);
                }
            });
        } else {
            SelectedTaskList.map((item: any, index) => {
                if (item.ProcessName === res.ProcessName) {
                    const ErrorList: { [key: string]: string } = {
                        [dataErrorField]: value ? "" : dataError.error
                    };
                    Object.assign(item, {
                        [name]: value,
                        Count,
                        PCA,
                        Efficiency,
                        PCAEfficiency,
                        ...ErrorList
                    });
                } else {
                    var GetMatchingids = FinalArray.filter((x: any) => x.ProcessName == item.ProcessName)
                    if (GetMatchingids.length > 0) {
                        if (res.ProcessName !== GetMatchingids[0].ProcessName) {
                            Object.assign(item, {
                                // WorkingHours: GetMatchingids[0].WorkingHours,
                                // FileName: GetMatchingids[0].FileName,
                                // FileNameTextError: GetMatchingids[0].FileNameTextError,
                                // WorkingHoursTextError: GetMatchingids[0].WorkingHoursTextError,
                                ...GetMatchingids[0],
                                Count,
                                PCA,
                                Efficiency,
                                PCAEfficiency,
                            });
                        }
                    }
                }
                updatedArray.push(item)
                if (index == SelectedTaskList.length - 1) {
                    setFinalArray(updatedArray);
                }
            });
        }

    };
    return (
        <>
            {open === true && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color='inherit' />
                </Backdrop>
            )}

            <Formik
                initialValues={{
                    FromDate: '',
                    WorkingStatus: '',
                    SelectedTaskList: ""
                }}
                onSubmit={handleSubmit}
            >
                {({ getFieldProps, isValid, isSubmitting }) => (
                    <Form>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mb-5'>
                                    <div className='form-group col-lg-2'>
                                        <label className='form-label fw-bold'>Select PM Name:</label>
                                        <Select
                                            className='basic-single'
                                            classNamePrefix='Select PM Name'
                                            value={SelectedPmSystem}
                                            options={getPmListData.map((pm) => ({ label: pm.PmName, value: pm._id }))}
                                            onChange={getPMSystem}
                                            isSearchable
                                        />
                                    </div>
                                    <div className='form-group col-lg-2'>
                                        <label className='form-label fw-bold'>Select Practice Name:</label>
                                        <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />

                                    </div>
                                    <div className='form-group col-lg-2'>
                                        <label className='form-label fw-bold'>Select Date:</label>
                                        <input
                                            type='date'
                                            className='form-control'
                                            onChange={handleInputChange}
                                            name="FromDate"
                                        // max={maxDate}
                                        // min={minDate}
                                        />
                                    </div>
                                    <div className='form-group col-lg-2'>
                                        <label className='form-label fw-bold'>
                                            <span className='required'>Present / Leave</span>
                                        </label>
                                        <div className='d-flex'>
                                            <div className='form-check form-check-inline   mt-4'>
                                                <Field className='form-check-input' checked={RadioChecked.StatusActive} type='radio' name='Practice' id='Practice' value='Yes' onChange={handleWorkingStatus} />
                                                <label className='form-check-label' htmlFor='flexRadioDefault2'>
                                                    Present
                                                </label>
                                            </div>
                                            <div className='form-check form-check-inline  mt-4'>
                                                <Field className='form-check-input' checked={RadioChecked.StatusInactive} type='radio' name='Practice' id='Practice' value='No' onChange={handleWorkingStatus} />
                                                <label className='form-check-label' htmlFor='flexRadioDefault2'>
                                                    Leave
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {formData.WorkingStatus === "Yes" ?
                                        <div className='form-group col-lg-4'>
                                            <label className='form-label fw-bold'>Tasks:</label>
                                            <Select
                                                className='basic-multi-select'
                                                classNamePrefix='select'
                                                value={SelectedTaskList}
                                                isMulti
                                                options={TaskListData}
                                                onChange={OnChangeTask}
                                                isSearchable
                                            />
                                        </div> : null}
                                </div>
                                {RadioChecked.StatusActive === true && (
                                    <>

                                        {FinalArray.length > 0 ? (
                                            <>


                                                <div className='row pt-1'>
                                                    <hr />
                                                    <div className='form-group col-lg-8'>
                                                        <table
                                                            id='kt_table_users'
                                                            className=' table table-striped align-middle fs-6 p-4 font-family-base no-footer w-full '
                                                        >
                                                            <thead className='thead-dark bg-dark'>
                                                                <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                                                                    <th className='px-3 py-2 text-light' scope='col'>
                                                                        S.No
                                                                    </th>
                                                                    <th className='text-center p-2 text-light'>Process Name</th>
                                                                    <th className='text-center p-2 text-light'>No's / Hours ( In Minutes)</th>
                                                                    <th className='text-center p-2 text-light'>Target</th>
                                                                    <th className='text-center p-2 text-light'>File Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='text-gray-600 fw-medium p-8'>
                                                                {FinalArray.map((res: any, _inde: any) => {
                                                                    return (
                                                                        <>
                                                                            <tr className='py-10 px-4' key={_inde}>
                                                                                <td className='px-3 py-2 text-center'>{Number(_inde) + 1}</td>

                                                                                <td className='px-3 py-2 text-center' title={`Target - ${res.Target} Target Unit : ${res.TargetUnit} `}>
                                                                                    {res.ProcessName ? res.ProcessName : '-'}
                                                                                </td>
                                                                                <td className='px-3 py-2 text-center'>
                                                                                    <input type="number" placeholder={res.TargetUnit} data-errorField="WorkingHoursTextError" data-error="Please fill the hour/ Mins" value={res.WorkingHours} className='w-70' name="WorkingHours" onChange={(e) => {
                                                                                        handleUpdateProduction(e, res)
                                                                                    }} />
                                                                                    <div className="fv-plugins-message-container">
                                                                                        <div className="fv-help-block">
                                                                                            <span role="alert">{res.WorkingHoursTextError}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className='px-3 py-2 text-center'>
                                                                                    {res.Target ? res.Target : '-'}
                                                                                </td>
                                                                                <td className='px-3 py-2 text-center'>
                                                                                    {res.ProofNeed == "Yes" ?
                                                                                        <>
                                                                                            <input type="text" data-errorField="FileNameTextError" data-error="Please Enter the file Name" value={res.FileName} className='w-70' name="FileName" onChange={(e) => {
                                                                                                handleUpdateProduction(e, res)
                                                                                            }} />
                                                                                            <div className="fv-plugins-message-container">
                                                                                                <div className="fv-help-block">
                                                                                                    <span role="alert">{res.FileNameTextError}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        : null
                                                                                    }

                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className='form-group col-lg-4'>

                                                        <table
                                                            id='kt_table_users'
                                                            className=' table table-striped align-middle fs-6 p-4 font-family-base no-footer w-full '
                                                        >
                                                            <thead className='thead-dark bg-dark'>
                                                                <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>

                                                                    <th className='text-center p-2 text-light'>Total {currentUser.RoleType != "AR-Caller" ? "Analysis" : "Called" } </th>
                                                                    {
                                                                        currentUser.RoleType != "AR-Caller" ? <th className='text-center p-2 text-light'>PCA</th> : null
                                                                    }
                                                                    <th className='text-center p-2 text-light'>Effiency</th>
                                                                    <th className='text-center p-2 text-light'>Others Production</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody className='text-gray-600 fw-medium p-8'>
                                                                <tr className='py-10 px-4' key={0}>
                                                                    <td className='px-3 py-2 text-center'>{UsersPracticeProduction.Count}</td>
                                                                    {
                                                                        currentUser.RoleType != "AR-Caller" ? <td className='px-3 py-2 text-center'>{UsersPracticeProduction.PCA}</td> : null
                                                                    }
                                                                    <td className='px-3 py-2 text-center'>{UsersPracticeProduction.Efficiency}%</td>
                                                                    <td className='px-3 py-2 text-center'>{UsersPracticeProduction.OthersProduction}%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>

                                            </>

                                        ) : null}
                                    </>
                                )}
                                {FinalArray && FinalArray.length > 0 ?
                                    <div className='row mb-5'>
                                        <div className='form-group col-lg-6'>
                                            <label className='form-label fw-bold'>Upload file:</label>
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input name="ClaimFiles" id="ClaimFiles" type="file" {...getInputProps({ multiple: false })} />
                                                <p>Drag 'n' drop some file here, or click to select files</p>
                                            </div>
                                            <aside>
                                                <ul>{acceptedFileItems}</ul>
                                            </aside>
                                        </div>
                                    </div>
                                    : null}
                                <div className='row mb-5'>
                                    <div className='form-group col-lg-4'>
                                        {!ApprovalStatus ? 
                                          <button type='submit' className='btn btn-primary' >
                                          Submit
                                      </button> : null}
                                      
                                        {UsersPracticeProduction.AttachmentName ? 
                                           <a  onClick={(e) => { DownloadFile(e) }} href={`/api/ProductionTrack/DownloadFile?UserId=${CurrentSelectedData.UserId}&WorkingDate=${CurrentSelectedData.WorkingDate.replace(/-/g, "_")}&PracticeId=${CurrentSelectedData.PracticeId}&FileName=${CurrentSelectedData.AttachmentName}`} className='btn btn-primary m-1'>  Dowload File</a> : null
                                        }
                                       
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

        </>
    );
};

export default UpdateProduction;
