import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select';

import moment from "moment-timezone"
import { KTSVG } from '../../../../_metronic/helpers'
import { useAuth } from '../../auth'
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert"
import CircularProgress from '@mui/material/CircularProgress';
import "../common/style.css";
import AddTask from "./AddTask"
const getUserList = `/users/getUsersList`
const getPracticeList = `/api/practice/getPracticeListById`
const getColumnsByUser = `/api/Claims/getColumnsByUser`
const getPmList = `/api/pmsystem/getPmList`
const GetTaskList = `/api/ProductionTrack/GetTaskList`
const CreateTask = `api/ProductionTrack/CreateTask`
const DeleteTask = `api/ProductionTrack/DeleteTask`

const ProductionTrack: React.FC = () => {

    const [ProviderEditData, setProviderEditData] = useState<any>([])
    const [SelectedPmSystem, setSelectedPmSystem] = useState({})
    const [SelectedPractice, setSelectedPractice] = useState([])
    const [PracticeListData, setPracticeListData] = useState([])
    const [FinalArray, setFinalArray] = useState<any>([])
    const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
    const [open, setOpen] = React.useState(false);
    const [AddProvider, setAddProvider] = useState<boolean>(false)
    const [EditProvider, setEditProvider] = useState<boolean>(false)
    const [formData, setFormData] = useState({
        FromDate: '',
        ToDate: '',
    });
    const [showModal, setshowModal] = useState<string>('')
    const [FinalProviderArray, setFinalProviderArray] = useState<any>([])

    const handleEditProviderItem = (itemID: any) => {
        console.log("FinalProviderArray", FinalProviderArray)
        const res = FinalProviderArray.filter((item: any) => item.globalID === itemID)

        setProviderEditData(res)
        setAddProvider(true)
        setEditProvider(true)
        setshowModal('show d-block')

    }
    const DeleteProviderItem = (Task_id: any,TaskName: String) => {
        var GetPracticeName: any = SelectedPractice
        swal({
            title: '',
            icon: "info",
            text: `Are sure you want delete  this task (${TaskName})`,
            buttons: {
                confirm: true,
            },
        }).then(async(value:any) => {
            if (value) {
                setOpen(true)
                await axios.post(DeleteTask,{
                    Task_id : Task_id,
                    PracticeId : GetPracticeName.value
                }).then(_res => {
                    if(_res.data.Response){
                        setOpen(false)
                        swal({
                            title: 'Success',
                            icon: "success",
                            text: `Task Deleted Successfully ...!`,
                        }).then((_res: any) => {
        
                        })
                    }
                }).catch((e)=>{
                    setOpen(false)
                    swal({
                        title: '',
                        icon: "info",
                        text: `Please wait some time..!`,
                    }).then((_res: any) => {
    
                    })
                })
                 const res = FinalProviderArray.filter((item: any) => item.Task_id !== Task_id)
        setFinalProviderArray(res)
            }
        });

       
    }

    function ProviderData(data: any) {
        var GetPracticeName: any = SelectedPractice
        setOpen(true)
        axios.get(GetTaskList, {
            params: {
                PracticeId: GetPracticeName.value
            }
        }).then((res) => {
            setOpen(false)
            setFinalProviderArray(res.data.Data)
        })
        // if (data.Edited === true) {
        //     for (let i = 0; i < FinalProviderArray.length; i++) {
        //         if (FinalProviderArray[i].globalID === data.globalID) {
        //             FinalProviderArray[i] = data
        //         }
        //     }
        //     setFinalProviderArray(FinalProviderArray)
        // } else {
        //     setFinalProviderArray((prev: any) => [...prev, data])
        // }
    }

    if (FinalArray.length > 0) {
        for (let i = 0; i < FinalArray.length; i++) {
            FinalArray[i].globalID = i + 1
        }
    }

    if (FinalProviderArray.length > 0) {
        for (let i = 0; i < FinalProviderArray.length; i++) {
            FinalProviderArray[i].globalID = i + 1
        }
    }
    const handleProvider = (e: any) => {
        e.preventDefault()
        var PMName: any = SelectedPmSystem
        var GetPracticeName: any = SelectedPractice
        if (!PMName.value) {
            swal({
                title: '',
                icon: "info",
                text: `Please Choose the PM Name`,
            })
            return false;
        }
        if (!GetPracticeName.value) {
            swal({
                title: '',
                icon: "info",
                text: `Please Choose the Practice Name`,
            })
            return false;
        }

        setAddProvider(true)
        setshowModal('show d-block')
    }
    const GetPmList = async () => {
        const getPmListData = await axios.get(getPmList)
        if (getPmListData.data) {

            setdatas(getPmListData.data.data)
        }
    }


    // const GetUsersList = async () => {
    //     const Result = await axios.get(getUserList)
    //     // setUserNameDatas(Result.data.data)
    // }


    useEffect(() => {

        //GetUsersList()
        GetPmList()
    }, [])



    const getPractice = (event: any) => {

        if (Object.keys(event).length > 0) {
            setSelectedPractice(event)
            setOpen(true)
            axios.get(GetTaskList, {
                params: {
                    PracticeId: event.value
                }
            }).then((res) => {
                setOpen(false)
                setFinalProviderArray(res.data.Data)
            })

        } else {
            setSelectedPractice([])

        }

    }

    const handleSubmit = async () => {

        var PMName: any = SelectedPmSystem
        var GetPracticeName: any = SelectedPractice
        if (!PMName.value) {
            swal({
                title: '',
                icon: "info",
                text: `Please Choose the PM Name`,
            })
            return false;
        }
        if (!GetPracticeName.value) {
            swal({
                title: '',
                icon: "info",
                text: `Please Choose the Practice Name`,
            })
            return false;
        }
        if (FinalProviderArray.length == 0) {
            swal({
                title: '',
                icon: "info",
                text: `Please Add Tasks`,
            })
            return false;
        }
        setOpen(true)
        axios.post(CreateTask, {
            TaskDetails: JSON.stringify(FinalProviderArray),
            PracticeId: GetPracticeName.value
        }).then((res) => {
            swal({
                title: '',
                icon: "info",
                text: `Task Added Successfully ...!`,
            })
            setOpen(false)
            setFinalProviderArray([])
        })
    }

    const getPMSystem = (event: any) => {
        //setSelectedPractice([])
        setSelectedPmSystem(event)
        if (event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }

    };

    var PmListData = [{ label: "Select", value: "" }]
    getPmListData.map((name) => (
        PmListData.push({
            label: name.PmName,
            value: name._id
        })
    ))
    const handleInputChange = async (event: any) => {
        const { name, value } = event.target;

        setFormData((prevState) => {
            return { ...prevState, [name]: value, }
        });
    }

    const DownloadFile = async (e: any) => {
        e.preventDefault();
        setOpen(true)
        var GetPracticeName: any = SelectedPractice
        await axios.get(e.target.href, { responseType: 'arraybuffer', }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Claims-${GetPracticeName.label}.xlsx`);
            setOpen(false);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
        return false;
    }


    return (
        <div >

            <div className="header">
                <span className="section">Claim Management</span>
                <span className="arrow">&rsaquo;</span>
                <span className='section'>Track Production</span>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    {/* <h3 className='fw-bolder m-0'>View Claims</h3> */}
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>PM System</span>
                    </label><br />
                    <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmSystem" name={"PmSystem"} options={PmListData} />
                </div>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span className='required'>Practice Name</span>
                    </label>
                    <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />
                </div>
                <div className='col-sm-2 fv-row'>

                    <div className='text-center pt-12'>
                        <button type='button' className='btn btn-primary' onClick={handleProvider}>
                            {' '}
                            Add More
                        </button>
                    </div>

                </div>
            </div>


            {FinalProviderArray.length > 0 ? (
                <>
                    <h4 className='mt-6 text-center text-uppercase'>Task List</h4>
                    <table
                        id='kt_table_users'
                        className=' table table-striped align-middle fs-6 p-4 mt-8 font-family-base no-footer w-full '
                    >
                        <thead className='thead-dark bg-dark'>
                            <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                                <th className='px-3 py-2 text-light' scope='col'>
                                    S.No
                                </th>
                                <th className='text-center p-2 text-light'>Process Name</th>
                                <th className='text-center p-2 text-light'>Target</th>
                                <th className='text-center p-2 text-light'>Target Unit</th>
                                <th className='text-center p-2 text-light'>Proof Need</th>
                                <th className='text-center p-2 text-light'>Created Time</th>
                                <th className='text-center p-2 text-light'>Updated Time</th>
                                <th className='text-center p-2 text-light'>Created By</th>
                                <th className='text-center p-2 text-light'>Updated By</th>
                                <th className='text-center p-2 text-light'></th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-medium p-8'>
                            {FinalProviderArray.map((res: any, _inde: any) => {
                                return (
                                    <>
                                        <tr className='py-10 px-4' key={_inde}>
                                            <td className='px-3 py-2 text-center'>{Number(_inde) + 1}</td>

                                            <td className='px-3 py-2 text-center'>
                                                {res.ProcessName ? res.ProcessName : '-'}
                                            </td>
                                            <td className='px-3 py-2 text-center'>
                                                {res.Target ? res.Target : '-'}
                                            </td>
                                            <td className='px-3 py-2 text-center'>
                                                {res.TargetUnit ? res.TargetUnit : '-'}
                                            </td>
                                            <td className='px-3 py-2 text-center'>
                                                {res.ProofNeed ? res.ProofNeed : '-'}
                                            </td>
                                            <td className='px-3 py-2 text-center'>
                                                {res.ProofNeed ? res.createdAt : '-'}
                                            </td>
                                            <td className='px-3 py-2 text-center'>
                                                {res.ProofNeed ? res.updatedAt : '-'}
                                            </td>
                                            <td className='px-3 py-2 text-center'>
                                                {res.ProofNeed ? res.created_by : '-'}
                                            </td>
                                            <td className='px-3 py-2 text-center'>
                                                {res.ProofNeed ? res.updated_by : '-'}
                                            </td>
                                            <td className='d-flex justify-content-around'>
                                                <a href='#' onClick={() => handleEditProviderItem(res.globalID)}>
                                                    <KTSVG
                                                        path='/media/icons/duotune/art/art005.svg'
                                                        className='svg-icon-2 svg-icon-gray-500'
                                                    />
                                                </a>

                                                <a href='#' onClick={() => DeleteProviderItem(res.Task_id,res.ProcessName)}>
                                                    <KTSVG
                                                        path='/media/icons/duotune/art/art011.svg'
                                                        className='svg-icon-2 svg-icon-gray-500 zoom'
                                                    />
                                                </a>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </>
            ) : null}
            {AddProvider && (
                <AddTask
                    SelectedPmSystem={SelectedPmSystem}
                    SelectedPractice={SelectedPractice}
                    setAddProvider={setAddProvider}
                    showModal={showModal}
                    onData={(data: any) => ProviderData(data)}
                />
            )}
            {EditProvider && (
                <AddTask
                    SelectedPmSystem={SelectedPmSystem}
                    SelectedPractice={SelectedPractice}
                    setEditProvider={setEditProvider}
                    setAddProvider={setAddProvider}
                    showModal={showModal}
                    ProviderEditData={ProviderEditData ? ProviderEditData : []}
                    onData={(data: any) => ProviderData(data)}
                />
            )}
            {/* {FinalProviderArray.length > 0 ?

                <div className='d-flex'>

                    <div className='text-center pt-15 mx-4'>
                        <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>

                </div>
                : null
            } */}


        </div>
    )

}
export { ProductionTrack }