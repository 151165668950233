export interface UserDetails {

    
    pm_system: string,
    practice_name: string,
    
}
export const UserDetailsDetails: UserDetails = {
    
    pm_system: "",
    practice_name: "",
    
}