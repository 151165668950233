import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select';

import moment from "moment-timezone"

import { useAuth } from '../../auth'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../common/style.css";
const getUserList = `/users/getUsersList`
const getPracticeList = `/api/practice/getPracticeListById`
const getColumnsByUser = `/api/Claims/getColumnsByUser`
const ARCollectionnByUserAPI = `/api/Claims/ARCollectionnByUser`
const getPmList = `/api/pmsystem/getPmList`
const GetPracticeIdbyUsers = "/users/GetPracticeIdbyUsers"

type PracticeType = {
    value:String,
    label:String
}
const ARCollectionbyUser: React.FC = () => {

    const [SelectedPmSystem, setSelectedPmSystem] = useState({})
    const [SelectedPractice, setSelectedPractice] = useState<PracticeType>({value:"",label:""})
    const [PracticeListData, setPracticeListData] = useState([])
    const [GetListData, setGetListData] = useState([])
    const [TotalCount, setTotalCount] = useState("")
    const [totalClaimBalance, settotalClaimBalance] = useState<any>(0)
    const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = useState({
        FromDate: '',
        ToDate: '',
    });



    const GetPmList = async () => {
        const getPmListData = await axios.get(getPmList)
        if (getPmListData.data) {

            setdatas(getPmListData.data.data)
        }
    }


    const GetUsersList = async () => {
        const Result = await axios.get(getUserList)
        // setUserNameDatas(Result.data.data)
    }


    useEffect(() => {

        GetUsersList()
        GetPmList()
    }, [])



    const getPractice = async(event: any) => {

        if (Object.keys(event).length > 0) {
            setSelectedPractice(event)
            await axios.get(`${GetPracticeIdbyUsers}?PracticeId=${event.value}&PageType=Callers`).then((response) => {
                var ResponseData = response.data.data
            }).catch((error) => {
                console.log(error)
            });

        } else {
            setSelectedPractice({value:"",label:""})

        }

    }


    const getPMSystem = (event: any) => {
        //setSelectedPractice([])
        setSelectedPmSystem(event)
        if (event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }

    };

    var PmListData = [{ label: "Select", value: "" }]
    getPmListData.map((name) => (
        PmListData.push({
            label: name.PmName,
            value: name._id
        })
    ))
    const handleInputChange = async (event: any) => {
        const { name, value } = event.target;

        setFormData((prevState) => {
            return { ...prevState, [name]: value, }
        });
    }

    const DownloadFile = async (e: any) => {
        e.preventDefault();
        setOpen(true)
        var GetPracticeName: any = SelectedPractice
        await axios.get(e.target.href, { responseType: 'arraybuffer', }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Claims-${GetPracticeName.label}.xlsx`);
            setOpen(false);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
        return false;
    }
    const Submit = async (e: any) => {
        setOpen(true)
        setGetListData([])
        var GetPracticeName: any = SelectedPractice
        const Result = await axios.get(`${ARCollectionnByUserAPI}?PracticeId=${GetPracticeName.value}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}&type=view`)
        setOpen(false)
        setTotalCount("")
        settotalClaimBalance(0)
        setGetListData(Result.data.data)
        var TotalCount = Result.data.data.reduce((accumulator:any, currentValue:any) => accumulator + currentValue.count, 0);
        var totalClaimBalanceVAlue = Result.data.data.reduce((accumulator:any, currentValue:any) => accumulator + currentValue.totalClaimBalance, 0);
        setTotalCount(TotalCount)
        settotalClaimBalance(totalClaimBalanceVAlue)

    }
    const options = { style: 'currency', currency: 'USD' };
    return (
        <div >

            <div className="header">
                <span className="section">Claim Management</span>
                <span className="arrow">&rsaquo;</span>
                <span className='section'>AR Collection By User</span>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    {/* <h3 className='fw-bolder m-0'>View Claims</h3> */}
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>PM System</span>
                    </label><br />
                    <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmSystem" name={"PmSystem"} options={PmListData} />

                </div>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span className='required'>Practice Name</span>
                    </label>

                    <Select maxMenuHeight={180} value={SelectedPractice} onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />

                </div>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span>From Date</span>
                    </label>
                    <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.FromDate} name="FromDate" id="FromDate" className='form-control' />
                </div>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span>To Date</span>
                    </label>
                    <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.ToDate} name="ToDate" id="ToDate" className='form-control' />
                </div>
                <div className='col-1 pt-13'>
                    <button onClick={(e) => { Submit(e) }} type="button"  className='btn btn-primary'>View</button>
                </div>
                <div className='col-1 pt-13'>
                    
                    <a onClick={(e) => { DownloadFile(e) }} href={`${ARCollectionnByUserAPI}?PracticeId=${SelectedPractice.value}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}&type=downlaod`} className='btn btn-primary'>Download</a>
                </div>

            </div>
            <div className='col-12 d-flex flex-column '>

        <h3 className="text-center mb-6 mt-4">AR Collection</h3>
        {GetListData.length > 0 ?
        <table id='kt_table_users'className=' table-bordered align-middle fs-6 p-4  font-family-base no-footer w-full ' >
          <thead>
            <tr>
              <th className="text-center p-2">User Name</th>
              <th className="text-center p-2">Role</th>
              <th className="text-center p-2">No of Claims</th>
              <th className="text-center p-2">Balance</th>
             
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-medium p-8'>
            
            {GetListData?.map((res: any, _inde: any) => {
              return (
                <tr className='py-10 px-4'>
                  <td className='px-3 py-2'>{res.userName}</td>
                  <td className='px-3 py-2'>{res.RoleName}</td>
                  <td className='px-3 py-2' style={{"textAlign" :"right"}}>{res.count}</td>
                  <td className='px-3 py-2' style={{"textAlign" :"right"}}>{res.totalClaimBalance.toLocaleString('en-US', options)}</td>
                </tr>
              )
            })}
          </tbody>
          <thead>
            <tr>
              <th className='px-3 py-2'colSpan={2} style={{textAlign:"center"}}>Grand Total</th>
              <th className='px-3 py-2' style={{"textAlign" :"right"}}>{TotalCount}</th>
              <th className='px-3 py-2' style={{"textAlign" :"right"}}>{totalClaimBalance.toLocaleString('en-US', options)}</th>
            </tr>
            </thead>
          
        </table> : null }
      </div>
        </div>


    )

}
export { ARCollectionbyUser }