import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';

interface WorkingTask {
  _id: string;
  PmId: string;
  PracticeId: string;
  TaskId: string;
  WorkingHours: number;
  Target: string;
  TargetUnit: string;
  ProcessName: string;
  ProofNeed: string;
  FileName: string;
}

interface DataItem {
  _id: string[];
  userName: string;
  RoleName: string;
  WorkingStatus: string;
  PracticeName: string;
  WorkingDate: string;
  UserId: string;
  TotalCount: number;
  TotalEfficiency: number;
  OthersProduction: number;
  Count: number;
  PCA: number;
  Efficiency: number;
  PCAEfficiency: number;
  WorkingTaskList: any;
}

interface TableProps {
  data: DataItem[];
  RoleName: String,
  UserID: String,
  WorkingDate : Date
}

const DataTable: React.FC<TableProps> = ({ data, RoleName,UserID ,WorkingDate}) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const toggleRow = (rowId: string) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };
  const grandTotalOthersEfficiency = data.reduce((acc, item) => acc + item.OthersProduction, 0);
  const grandTotalEfficiency = data.reduce((acc, item) => acc + item.Efficiency, 0);
  const grandTotalPCAEfficiency = data.reduce((acc, item) => acc + item.PCAEfficiency, 0);

  return (
    <>
      <Table bordered hover align-middle >
        <thead>
          <tr className='text-center'>
            <th>#</th>
            <th>Practice Name</th>
            <th>Total Count</th>
            {
              RoleName != "AR-Caller" ? <><th >PCA</th><th >PCA Efficiency</th></> : null
            }
            <th> Efficiency</th>
            <th>Others Efficiency</th>
            <th>Total Efficiency</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={item._id[0]}>
              <tr className='text-center'>
                <td>{index + 1}</td>
                <td>{item.PracticeName}</td>
                <td>{item.Count}</td>
                {
                    RoleName != "AR-Caller" ? <>
                    <td>{item.PCA}</td>
                    <td>{item.PCAEfficiency}%</td>
                    </> : null
                  }
                <td>{item.Efficiency}%</td>
                <td>{item.OthersProduction}%</td>
                <td>{Number(Number(item.Efficiency) + Number(item.OthersProduction) + Number(item.PCAEfficiency)).toFixed(2)}%</td>
                <td>
                  <Button
                    variant={expandedRow === item._id[0] ? 'primary m-1 fa fa-minus' : 'primary m-1 fa fa-plus-square'} onClick={() => toggleRow(item._id[0])}></Button>
                </td>
              </tr>
              {expandedRow === item._id[0] && (
                <tr>
                  <td colSpan={10}>

                    <div className='badge-dark text-center'> Task Histroy</div>

                    <Table size="sm">
                      <thead >
                        <tr className='text-center'>
                          <th>S.No</th>
                          <th>Process Name</th>
                          <th>Working Hours</th>
                          <th>Target</th>
                          <th>Target Unit</th>
                          <th>Proof Needed</th>
                          <th>File Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.WorkingTaskList.map((task:any, idx:any) => (
                          <tr className='text-center' key={task._id}>
                            <td>{idx + 1}</td>
                            <td>{task.ProcessName}</td>
                            <td>{task.WorkingHours}</td>
                            <td>{task.Target}</td>
                            <td>{task.TargetUnit}</td>
                            <td>{task.ProofNeed}</td>
                            <td>{task.FileName}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          <tr className='text-center font-weight-bold'>
            <td colSpan={4} style={{ textAlign: 'right' }}>Grand Total:</td>
            {RoleName !== "AR-Caller" && <td>{grandTotalPCAEfficiency}%</td>}
            <td>{grandTotalEfficiency}%</td>
            <td>{ Number(grandTotalOthersEfficiency).toFixed(2)}%</td>
            <td>{Number(Number(grandTotalEfficiency) + Number(grandTotalOthersEfficiency)).toFixed(2)}%</td>
            
            <td></td>
          </tr>
        </tbody>
      </Table>
    </>

  );
};

export default DataTable;
