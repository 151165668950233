import React, { useState, useEffect } from 'react'
import axios from "axios";
import Select from 'react-select';
import { DataGridPro, GridColDef, GridFilterModel, DataGridProProps, GridSortModel, GridRowParams, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridValueGetterParams, GridRowModel, getGridSingleSelectOperators, GridComparatorFn, GridFilterItem, GridCellParams } from '@mui/x-data-grid-pro';
import Button, { ButtonProps } from '@mui/material/Button';
import { buttonBaseProps } from './Common/buttonBaseProp';
const getAllPracticeList = `/api/practice/getAllPracticeList`
const GetUtlization = "/api/Claims/GetUtlization"
const FileDownlaod = `api/Claims/FileDownlaod`

const BotUtlization: React.FC = () => {

    const [SelectedPractice, setSelectedPractice] = useState([])
    const [SelectedPayer, setSelectedPayer] = useState([])
    const [PracticeListData, setPracticeListData] = useState([])
    const [ListForPayer, setListForPayer] : any= useState([{label:"All",value:"All"},{label:"UHC",value:"UHC"},{label:"Aetna",value:"Aetna"},{label:"Humana",value:"Humana"},{label:"BCBS",value:"BCBS"},{ value: "AMERICHOICE", label: "Ameri Choice" },{ value: "EMPIRE", label: "Empire BC Medicad Health Plus" }])
    const [open, setOpen] = React.useState(false);
    const [isLoading,setLoading] = useState(false)
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        totalClaimBalance : 0.00,
        pageSize: {
            page: 0,
            pageSize: 100
        }
        // page: 1,
        // pageSize: 20
    })
    const [formData, setFormData] = useState({
        FromDate: '',
        ToDate: '',
    });

    const GetPracticeList = async () => {
        axios.post(getAllPracticeList).then((res:any) => {
            var GetPracticeData :any = [{ label: "All", value: "All" }]
            res.data.data.map((name:any) => (
                GetPracticeData.push({
                    label: name.label,
                    value: name.value
                })
            ))
            setPracticeListData(GetPracticeData)
        })
    }
    useEffect(() => {
        GetPracticeList()
    }, [])
    const getPractice = (event: any) => {

        if (Object.keys(event).length > 0) {
            setSelectedPractice(event)


        } else {
            setSelectedPractice([])

        }

    }
    const getPayer = (event: any) => {
        if (Object.keys(event).length > 0) {
            setSelectedPayer(event)
        } else {
            setSelectedPayer([])
        }
    }
    const GetUtlizationbuttonApi = async () => {
        if(SelectedPractice.length > 0){
            setOpen(true)
            setPageState(old => ({ ...old, isLoading: true }))
            await axios.get(`${GetUtlization}?Payer=${JSON.stringify(SelectedPayer)}&PracticeId=${JSON.stringify(SelectedPractice)}&&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&StartDate=${formData.FromDate}&ToDate=${formData.ToDate}`).then((response) => {
                setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total}))
                
            }).catch((error) => console.log(error));
            return false;
        }
    }
    useEffect(() => {
        GetUtlizationbuttonApi()
    }, [pageState.pageSize.page, pageState.pageSize.pageSize])
    const GetUtlizationbutton = async (e: any) => {
        e.preventDefault();
        setOpen(true)
        setPageState(old => ({ ...old, isLoading: true }))
        await axios.get(`${GetUtlization}?Payer=${JSON.stringify(SelectedPayer)}&PracticeId=${JSON.stringify(SelectedPractice)}&&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&StartDate=${formData.FromDate}&ToDate=${formData.ToDate}`).then((response) => {
            
            setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total}))
            
        }).catch((error) => console.log(error));
        return false;
    }
    const handleExport = async (_e: any) => {
        
        axios.get(`${GetUtlization}?Payer=${JSON.stringify(SelectedPayer)}&PracticeId=${JSON.stringify(SelectedPractice)}&&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&StartDate=${formData.FromDate}&ToDate=${formData.ToDate}&type=download`).then((response) => {
            const url = window.location.origin+"/"+response.data;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',"");
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
    }
    const CustomToolBar = () => {

        return (<GridToolbarContainer>
            <Button
                {...buttonBaseProps}
                onClick={(e) => handleExport(e)}
            >Export</Button>
           
        </GridToolbarContainer>
        );
    }
    const DownloadFile = async (ids: any,Type:String) => {

        axios.get(FileDownlaod,{params : {fileName : ids.OutputPath}}).then((response) => {
          const url = window.location.origin + "/" + response.data;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', '');
          document.body.appendChild(link);
          link.click();
        }).catch((error) => console.log(error));
    }
    const handleInputChange = async (event: any) => {
        const {name, value} = event.target
        setFormData((prevState) => {
          return {...prevState, [name]: value}
        })
      }
    const columns: GridColDef[] = [
        { align: 'center', headerAlign: "center", field: 'PMName', headerName: 'PM Name', width: 100, },
        { align: 'center', headerAlign: "center", field: 'PracticeName', headerName: 'Practice Name', width: 100, },
        { align: 'center', headerAlign: "center", field: 'PayerName', headerName: 'Payer Name', width: 100, },
        { align: 'center', headerAlign: "center", field: 'FilePath', headerName: 'File Path', width: 100, },
        { align: 'center', headerAlign: "center", field: 'Status', headerName: 'Status', width: 100, },
        { align: 'center', headerAlign: "center", field: 'Input', headerName: 'Input', width: 100, },
        { align: 'center', headerAlign: "center", field: 'Output', headerName: 'Output', width: 100, },
        { align: 'center', headerAlign: "center", field: 'Exception', headerName: 'Exception', width: 100, },
        { align: 'center', headerAlign: "center", field: 'createdAt', headerName: 'Created Date', width: 100, },
        {
            align: 'center', headerAlign: "center", field: 'OutputPath', headerName: 'Download', width: 200, renderCell: ({ row }: Partial<GridRowParams>) =>
                <a href="#"  className='' onClick={() => DownloadFile(row,"No")}>
                    Download
                </a>,
        },
    ]
    return (
        <div >

            <div className="header">
                <span className="section">Bot Utlization</span>
            </div>
            
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    {/* <h3 className='fw-bolder m-0'>View Claims</h3> */}
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-3 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span >Payer Name</span>
                    </label>
                    <Select maxMenuHeight={180} value={SelectedPayer} onChange={getPayer} id="Practice"  name={"Practice"} options={ListForPayer} />

                </div>
                <div className='col-sm-3 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span className='required'>Practice Name</span>
                    </label>

                    <Select maxMenuHeight={180} value={SelectedPractice} isMulti onChange={getPractice} id="Practice"  name={"Practice"} options={PracticeListData} />

                </div>
                <div className='col-sm-2 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                    <span>From Date</span>
                    </label>
                    <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.FromDate} name="FromDate" id="FromDate" className='form-control' />
                </div>
                <div className='col-sm-2 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                    <span>To Date</span>
                    </label>
                    <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.ToDate} name="ToDate" id="ToDate" className='form-control' />
                </div>
                <div className='col-1 pt-13'>
                    <button onClick={(e) => { GetUtlizationbutton(e) }} type="button" className='btn btn-primary'>Submit</button>
                </div>

            </div>
            <div style={{ width: "100%", }} className="pt-20">
                <DataGridPro
                    rowHeight={40}
                    autoHeight={true}
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pageSizeOptions={[100, 200, 300, 400, 500]}
                    paginationModel={{
                        page: pageState.pageSize.page == 0 ? pageState.pageSize.page : pageState.pageSize.page,
                        pageSize: pageState.pageSize.pageSize
                    }}
                    paginationMode="server"
                    onPaginationModelChange={(newPageSize: any) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                    pagination
                    columns={columns}
                    components={{ Toolbar: CustomToolBar }}
                    density="compact"
                />
            </div>
        </div>


    )

}
export { BotUtlization }