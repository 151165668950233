import React, { useState, useEffect,useRef } from 'react'
import { FC } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGridPro, GridColDef, GridFilterModel, DataGridProProps, GridSortModel, GridRowParams, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridValueGetterParams, GridRowModel, getGridSingleSelectOperators, GridComparatorFn, GridFilterItem, GridCellParams } from '@mui/x-data-grid-pro';
import Button, { ButtonProps } from '@mui/material/Button';
import axios from "axios";
import moment from "moment";
import { TableStyles } from "../../wizards/common/TableStyle"
import { HideFooterButtons } from "../../wizards/common/HideFooterButtons"
import { buttonBaseProps } from '../../wizards/common/buttonBaseProp';
import "../../wizards/common/style.css";

const UpdateColumnsByUser = `/api/Claims/UpdateColumnsByUser`
const ExportLastLoginManagers = `/api/Report/ExportLastLoginManagers`

export interface ChildProps {
    passChildData?: any;
    SelectedPmSystem?: Object;
    SelectedPractice?: Object;
    SelectedManager?: Object;
    FromDate : string,
    SelectMonth : string,
    ToDate : string,
    isSubmit : boolean
  }
  
type Props = {
    passChildData?: any;
    UserData?: {
        SelectedPmSystem? : Object,
        SelectedPractice? : Object,
        FromDate : string,
        ToDate : string,
        SelectedManager?: Object,
    }
}

const NotUsedManagerLogin: FC<Props> = (passChildData:any) => {

    
    const [DefaultColumns, setDefaultColumns] = useState({ Bill: false, DateOfService: false, PatientName: false, PayerName: false, ClaimStatus: false, ClaimBilledAmount: false, ClaimPayment: false, ClaimBalance: false, Account: false, ProviderName: false, SystemStatus: false, Aging: false, DueDate: false, Comments: false, updatedAt: false, FirstName: false, Location: false, MemberId: false, PayerMix: false, IntialClaimDate: false, ClaimAdjustemnt: false, Stage: false, RoleResponsibilityCategory: false, DenialReason: false, ServiceType: false, RootCause: false, Modifier: false, PCAStatus: false, ProcedureCode: false, FacilityName: false, PayerResponsibility: false, Indication: false, ProcedureBalance: false, FiledStatus: false, PatientDOB: false, AdjustmentReason: false, CompanyName: false, OrginalICN: false, Diagnosis: false, SecondaryInsuranceCompany: false, DOE: false, Unit: false, ProcedureBilledAmount: false, ProcedurePayment: false, ProcedureAdjustment: false,Status:false })
    const prevProps = useRef<any>();
    const [isLoading, setLoading] = useState(false)
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'updatedAt',
            sort: 'desc',
        },
    ]);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        totalClaimBalance : 0.00,
        pageSize: {
            page: 0,
            pageSize: 250
        }
        // page: 1,
        // pageSize: 20
    })
    useEffect(() => {
        const fetchData = async () => {
          try {
            
            if(Object.keys(passChildData.UserData).length > 0){
                if (prevProps.current !== passChildData) {
                    setLoading(true)
                    var Result = await axios.get(`${ExportLastLoginManagers}?pmid=${JSON.stringify(passChildData.UserData?.SelectedPmSystem)}&practicelist=${JSON.stringify(passChildData.UserData?.SelectedPractice)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=view&sort=${JSON.stringify(sortModel)}&StartDate=${passChildData.UserData?.FromDate}&ToDate=${passChildData.UserData?.ToDate}`)
                    setPageState(old => ({ ...old, isLoading: false, data: Result.data.data, total: Result.data.total }))
                    setLoading(false)
                }
            }
            prevProps.current =  passChildData;
          } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    }, [passChildData,pageState.pageSize.page, pageState.pageSize.pageSize, filterModel.items, sortModel]);
    
    const handleExport = async (_e: any) => {
        setLoading(true)
        axios.get(`${ExportLastLoginManagers}?pmid=${JSON.stringify(passChildData.UserData?.SelectedPmSystem)}&practicelist=${JSON.stringify(passChildData.UserData?.SelectedPractice)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=downlaod&sort=${JSON.stringify(sortModel)}&StartDate=${passChildData.UserData?.FromDate}&ToDate=${passChildData.UserData?.ToDate}`,{responseType: 'arraybuffer',}).then((response) => {
            setLoading(false)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Not Used Manager Login-${moment().format("YYYY-MM-DD")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
        
    }
    const CustomToolBar = () => {

        return (<GridToolbarContainer>

            {/* <GridToolbarColumnsButton />
            <GridToolbarFilterButton /> */}
            <GridToolbarDensitySelector />
            <Button
                {...buttonBaseProps}
                onClick={(e) => handleExport(e)}
            >Export</Button>
        </GridToolbarContainer>
        );
    }
   
    const columns: GridColDef[] = [
        { align: 'center', headerAlign: "center", field: 'UserName', headerName: 'User Name', width: 150, editable: true,},
        { align: 'center', headerAlign: "center", field: 'roles', headerName: 'Role', width: 200, },
        { align: 'center', headerAlign: "center", field: 'practice_names', headerName: 'Assigned practices', width: 150, },
        { align: 'center', headerAlign: "center", field: 'EmailId', headerName: 'Email ID', width: 200, },
        { align: 'center', headerAlign: "center", field: 'LastLoginDate', headerName: 'Last Login Date', width: 200, },
    ]

    const setColumnVisibilityModel = (e: any) => {
        setDefaultColumns(e)
        axios.post(UpdateColumnsByUser, { Columns: JSON.stringify([e]), PageType: "ViewClaims" }).then((res) => {
        })
    }
    return (
        <div >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ width: "100%", }} className="pt-5">
                <DataGridPro
                    sx={TableStyles}
                    rowHeight={40}
                    componentsProps={HideFooterButtons}
                    autoHeight={true}
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                   
                    pageSizeOptions={[50, 100, 150, 200, 250]}
                    
                    paginationModel={{
                        page: pageState.pageSize.page == 0 ? pageState.pageSize.page : pageState.pageSize.page,
                        pageSize: pageState.pageSize.pageSize
                    }}
                    paginationMode="server"
                    onPaginationModelChange={(newPageSize: any) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                    pagination
                    columns={columns}
                    density="compact"
                    filterModel={filterModel}
                    onColumnVisibilityModelChange={(newModel: any) => setColumnVisibilityModel(newModel)}
                    onSortModelChange={(model: any) => setSortModel(model)}
                    columnVisibilityModel={DefaultColumns}
                    
                    components={{ Toolbar: CustomToolBar }}

                    sortModel={sortModel}
                    onFilterModelChange={(newFilterModel: React.SetStateAction<GridFilterModel>) => setFilterModel(newFilterModel)}
                />
            </div>
        </div>
    )

}
export default  NotUsedManagerLogin;