import { useState, useEffect } from 'react'
import Select from 'react-select';
import axios from 'axios'
import swal from "sweetalert"
import { KTSVG } from '../../../../_metronic/helpers'
const CreateTask = `api/ProductionTrack/CreateTask`

type ProviderType = {label:String,value:String}

const AddTask = ({ setAddProvider, onData, showModal, ProviderEditData, setEditProvider, SelectedPmSystem, SelectedPractice }: any) => {
  
  const [ButtonName, setButtonName] = useState<any>()
  const [TargetUnitValue, setTargetUnitValue] = useState<ProviderType>({label:"",value:""})
  const [SpecialityValue, setSpecialityValue] = useState<any>()
  const [Speciality, setSpeciality] = useState<any>([])
  const [formData, setFormData] = useState<any>({
    ProcessName: "",
    Target: "",
    TargetUnit: "",
    ProofNeed: "",
    Task_id: '',
    Edited: false,
    globalID: 0,
  });

  const [RadioChecked, setRadioChecked] = useState<any>(
    {
      StatusActive: false,
      StatusInactive: false,

    })


  const TargetUnitsList: any = [
    { value: 'Nos', label: 'Nos' },
    { value: 'Mins', label: 'Mins' },
  ]

  const handleLocationStatus = (e: any) => {

    if (e.target.value === "Yes") {
      setRadioChecked({ StatusActive: true })
    }
    else if (e.target.value === "No") {
      setRadioChecked({ StatusInactive: true })
    }
    setFormData((prevState: any) => {
      return { ...prevState, ProofNeed: e.target.value }
    });
  }
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      }
    });
  };

  const handleTargetUnitChange = (e: any) => {
    if (e.label == "Mins") {
      setFormData((prevState: any) => {
        return { ...prevState, Target: "" }
      });
    }
    setTargetUnitValue(e)
    setFormData((prevState: any) => {
      return { ...prevState, TargetUnit: e.label }
    });
  }

  const handleSave = (e: any) => {
    e.preventDefault()

    if (!formData.ProcessName) {
      swal({
        title: '',
        icon: "info",
        text: `Process Name is manadatory`,
      })
      return false;
    }
    if (!formData.TargetUnit) {
      swal({
        title: '',
        icon: "info",
        text: `Target Unit is manadatory`,
      })
      return false;
    }
    if(formData.TargetUnit == "Nos"){
      if (!formData.Target) {
        swal({
          title: '',
          icon: "info",
          text: `Target is manadatory`,
        })
        return false;
      }
    }
    if (!formData.ProofNeed) {
      swal({
        title: '',
        icon: "info",
        text: `Proof Need is manadatory`,
      })
      return false;
    }
    axios.post(CreateTask, {
      TaskDetails: JSON.stringify([formData]),
      PracticeId: SelectedPractice.value
    }).then((res) => {
      swal({
        title: '',
        icon: "info",
        text: `${formData.Edited ? "Task Updated Successfully ...!" : "Task Added Successfully ...!"}`,
      })
      onData(formData)
      setAddProvider(false)
      setEditProvider(false)
    })

  }

  useEffect(() => {
    if (ProviderEditData && setEditProvider) {
      setButtonName("Update")
      ProviderEditData.map((res: any, ind: any) => {
        setFormData({

          ProcessName: res.ProcessName,
          Target: res.Target,
          TargetUnit: res.TargetUnit,
          ProofNeed: res.ProofNeed,
          Task_id: res.Task_id,
          Edited: true,
          globalID: res.globalID,

        })
        setTargetUnitValue({ label: res.TargetUnit, value: res.TargetUnit })
        if (res.ProofNeed === "Yes") {
          setRadioChecked({ StatusActive: true })
        }
        else if (res.ProofNeed === "No") {
          setRadioChecked({ StatusInactive: true })
        }
      })
    }
    else {
      setButtonName("Create")
      setFormData({
        ProcessName: '',
        Target: '',
        TargetUnit: '',
        ProofNeed: '',
        Task_id: '',
        globalID: 0,
        Edited: false
      })
      setSpecialityValue([])
      setTargetUnitValue({label:"",value:""})
      setRadioChecked({ StatusActive: false, StatusInactive: false })
    }

  }, [])

  const handleClose = () => {
    setAddProvider(false)
    setEditProvider(false)
  }
  return (
    <div className={`modal fade ${showModal}`} id='kt_modal_add_user' role='dialog' tabIndex={-1} aria-modal='false' >
      <div className='modal-dialog modal-dialog-centered modal-xl'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='fw-bolder'>Add Task</h2>

            <div
              onClick={handleClose}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              data-kt-users-modal-action='close'
              style={{ cursor: 'pointer' }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>



          <div className=' w-full p-6 align-content-center' >
            {/* 1st column */}
            <div className='row'>
              <div className='col-6 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Process Name</span>
                </label>
                <input className='p-2 w-70' name="ProcessName" value={formData.ProcessName} onChange={handleInputChange} />
              </div>
              <div className='col-6 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Target Unit </span>
                </label>
                <Select maxMenuHeight={150} className='w-70' name="SpecialityLoc" value={TargetUnitValue} options={TargetUnitsList} onChange={handleTargetUnitChange} />
              </div>
              {TargetUnitValue.label == "Nos" ?
                <div className='col-6 fv-row mb-4 d-flex flex-column'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Target</span>
                  </label>
                  <input className='p-2 w-70' name="Target" value={formData.Target} onChange={handleInputChange} />
                </div> : null
              }




              <div className='col-6 fv-row mb-4 d-flex flex-column'>
                <label className='col-form-label fw-bold fs-6'>
                  <span className='required'>Proof Need</span>
                </label>
                <div className='d-flex'>
                  <div className='form-check form-check-inline mt-4 '>
                    <input
                      className='form-check-input'
                      checked={RadioChecked.StatusActive}
                      type='radio'
                      name='Practice'
                      id='Practice'
                      value='Yes'
                      onChange={handleLocationStatus}
                    />
                    <label className='form-check-label' htmlFor='flexRadioDefault2'>
                      Yes
                    </label>
                  </div>
                  <div className='form-check form-check-inline mt-4'>
                    <input
                      className='form-check-input'
                      checked={RadioChecked.StatusInactive}
                      type='radio'
                      name='Practice'
                      id='Practice'
                      value='No'
                      onChange={handleLocationStatus}
                    />
                    <label className='form-check-label' htmlFor='flexRadioDefault2'>
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className='d-flex'>
                <div className='text-center pt-6 me-4'>
                  <button type='button' className='btn btn-primary rounded ' onClick={handleSave}> {ButtonName}</button>
                </div>
              </div>
            </div>

          </div>


        </div>
        <div>




        </div>
      </div>

    </div>
  )


}


export default AddTask

