import { GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";

const NeedToCheckOperator: GridFilterOperator[] = [
    {
        label: 'Select',
        value: '',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }

            return (params): boolean => {
                return Number(params.value) >= Number(filterItem.value);
            };
        },
    },
    {
        label: 'Yes',
        value: "Yes",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }

            return (params): boolean => {
                return Number(params.value) >= Number(filterItem.value);
            };
        },
    },
    {
        label: 'No',
        value: "No",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }
            return (params): boolean => {
                return Number(params.value) >= Number(filterItem.value);
            };
        },
    },
]

export {NeedToCheckOperator}