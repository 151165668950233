import React, { useState, useEffect, HTMLAttributes ,useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import axios from "axios";
import { useAuth } from '../../../app/modules/auth'
import { Chart } from "react-google-charts";
import Select from 'react-select';
import swal from "sweetalert"
import moment from 'moment';
import { capitalize } from '@mui/material';
import '../../../_metronic/assets/css/global.css'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TableRows from '../../components/TableRows';
import AgingTable from '../../components/AgingTable';



const API_URL = process.env.REACT_APP_THEME_API_URL
const getPracticewiseDashboard = `/users/getPracticewiseDashboard`
const getPmList = `/api/pmsystem/getPmList`
const getPracticeList = `/api/practice/getPracticeListById`


const options = { style: 'currency', currency: 'USD' };

interface TableHeaderCellProps extends HTMLAttributes<HTMLTableCellElement> {
    colSpan: number;
}

type GrandTotalType = {
    totalAmount0:number
    totalCount0:number
    totalAmount60:number
    totalCount60:number
    totalAmount120:number
    totalCount120:number
}

type AgingTotalType={
    totalAmount:number
    totalCount:number
}

const thead: React.CSSProperties = {
    color: "#fff",
    backgroundColor: "#002d72",
    top: 70,
    position: 'sticky',
};

const arrow_right: React.CSSProperties = {
    width: "30px",
    height:"30px",
    
};

const Back: React.CSSProperties = {
    background: `linear-gradient(90deg, rgba(232,217,207,1) 17%, rgba(170,208,194,1) 100%, rgba(193,100,82,1) 100%)`,
  
};




const Practicewise: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser }: any = useAuth()
    const [UserNameDatas, setUserNameDatas] = useState([{ FirstName: "", _id: "" }])
    const [PieChartData, setPieChartData] = useState([])
    const [label, setLabel] = useState([])
    const [ChartData, setChartData] = useState<any>([])
    const [ChartDataForClaim, setChartDataForClaim] = useState<any>([])
    const [ChartProviderData, setChartProviderData] = useState<any>([])
    const [ChartProviderDataForClaim, setChartProviderDataForClaim] = useState<any>([])
    const [ChartStatusWiseData, setChartStatusWiseData] = useState<any>([])
    const [ChartStatusWiseDataForClaim, setChartStatusWiseDataForClaim] = useState<any>([])
    const [ChartAgeingWiseChartData, setChartAgeingWiseChartData] = useState([])
    const [ChartAgeingWiseChartDataForClaim, setChartAgeingWiseChartDataForClaim] = useState([])
    const [RootCauseDataWiseData, setRootCauseDataWiseData] = useState<any>([])
    const [RootCauseDataWiseDataForClaim, setRootCauseDataWiseDataForClaim] = useState<any>([])
    const [open, setOpen] = React.useState(false);
    const [AgeingWiseTableData, setAgeingWiseTableData] = useState([])
    const [PayerNameTableData, setPayerNameTableData] = useState<any>([])
    const [ProviderNameWiseTableData, setProviderNameWiseTableData] = useState<any>([])
    const [StatusWiseTableData, setStatusWiseTableData] = useState([])
    const [RootCauseTableData, setRootCauseTableData] = useState([])
    const [selectedRadio, setSelectedRadio] = useState<any>('');
    const [showing, setShowing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [myLoading, setMyLoading] = useState(false)
    const[reload,setReload]=useState<boolean>(false)
    const [RadioChecked,setRadioChecked]=useState<any>(
        {
            PayerRadio:false,
            AgingRadio:false,
            StatusRadio:false,
            ProviderRadio:false,
            RootCauseRadio:false
        })
    



    const [UserList, setUserList] = useState([])
   
    const [SelectedPmSystem, setSelectedPmSystem] = useState({})
    const [SelectedPractice, setSelectedPractice] = useState([])
    const [PracticeListData, setPracticeListData] = useState([])
    const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
   const [Disabled,setDisabled]=useState<boolean>(false)
   const [RadioDisabled,setRadioDisabled]=useState<boolean>(true)
   const [GrandTotal,setGrandTotal]=useState<GrandTotalType>({
    totalAmount0:0,
    totalCount0:0,
    totalAmount60:0,
    totalCount60:0,
    totalAmount120:0,
    totalCount120:0
})

const [AgingTotal,setAgingTotal]=useState<AgingTotalType>({
   totalAmount:0,
   totalCount:0,
  
})


 //Payer chart options
 const ChartOptionsForClaim={
    title: "Payer Wise vs No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Payer Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'],
}

const ChartOptions={
    title: "Payer Wise vs Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Payer Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } },
    colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
}

//Status chart options

const ChartOptionsStatusForClaim={ title: "Status Wise ~  No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Status Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: " No of claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'], }


const ChartOptionsStatus={ title: "Status Wise ~ Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Status Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } },  colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2" }


//Provider chart options
const ChartOptionsProvider={ title: "Provider Wise ~ Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Provider Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } },  colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2" }


const ChartOptionsProviderForClaim={ title: "Provider Wise ~ No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Provider Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'], }


//Aging chart options
const ChartOptionsPracticeForClaim={ title: "Aging Wise ~ No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Aging Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } }, backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'], }

const ChartOptionsPractice={ title: "Aging Wise ~ Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Aging Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } }, colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2" }

//Root chart Options
const ChartOptionsRootCause={ title: "Rootcause Wise ~ Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Rootcause Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } }, colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2" }

const ChartOptionsRootCauseForClaim={ title: "Rootcause Wise ~ No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Rootcause Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } },   backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'],
}


    useEffect(() => {
        GetPmList()
    }, [])

useEffect(()=>{
    FetchBackData()
},[])


    const GetPmList = async () => {
        const getPmListData = await axios.get(getPmList)
        if (getPmListData.data) {

            setdatas(getPmListData.data.data)
        }
    }

   

    const getPractice = (event: any) => {
        setSelectedPractice(event)
        sessionStorage.setItem("PracticePr",JSON.stringify(event))
             if(Object.keys(event).length >0){
   setRadioDisabled(false)
        }
        else{
           
            setRadioDisabled(true)
        }

    }
    
var PmSystemPr:any;
var PracticePr:any;
var RadioPr:any;
    

    async function fetchData(radioValue:string){
        setSelectedRadio(radioValue)
        setDisabled(false)
       
        setMyLoading(true)
        setShowing(false)
        setUserList([])
        setChartData([])
        setPayerNameTableData([])
        setRootCauseDataWiseData([])
        setRootCauseTableData([])
        setChartStatusWiseData([])
        setChartStatusWiseDataForClaim([])
        setStatusWiseTableData([])
        setChartProviderData([])
        setChartProviderDataForClaim([])
        setProviderNameWiseTableData([])
        setChartAgeingWiseChartData([])
        setAgeingWiseTableData([])
        setChartAgeingWiseChartDataForClaim([])
        setRootCauseDataWiseDataForClaim([])
        setGrandTotal({
            totalAmount0:0,
            totalCount0:0,
            totalAmount60:0,
            totalCount60:0,
            totalAmount120:0,
            totalCount120:0
        })
        setAgingTotal({
            totalAmount:0,
            totalCount:0
          })

        setLoading(true)
        if(radioValue === "PAYER WISE"){
            setRadioChecked({PayerRadio:true})
         } else if(radioValue === "AGING WISE"){
            setRadioChecked({AgingRadio:true})
         } else if(radioValue === "STATUS WISE"){
            setRadioChecked({StatusRadio:true})
         } else if(radioValue === "PROVIDER WISE"){
            setRadioChecked({ProviderRadio:true})
         } else if(radioValue === "ROOTCAUSE WISE"){
            setRadioChecked({RootCauseRadio:true})
         } 
    
        if (Object.keys(SelectedPractice).length > 0) {
            

            axios.get(`${getPracticewiseDashboard}?PracticeId=${JSON.stringify(SelectedPractice)}&radio=${radioValue}`).then(_res => {


                if (!_res.data.Result) {

                    swal({
                        title: '',
                        icon: "info",
                        text: `${_res.data.Message}`,
                    }).then((_res: any) => {

                    })
                }
                if (_res.data.Radio === "PAYER WISE") {
               
                    setChartData(_res.data.PayerNameData)
                    setChartDataForClaim(_res.data.PayerNameDataForClaim)
                    setPayerNameTableData(_res.data.PayerNameDataTable)
                    setGrandTotal({
                        totalAmount0:_res.data.totalAmount0,
                        totalCount0:_res.data.totalCount0,
                        totalAmount60:_res.data.totalAmount60,
                        totalCount60:_res.data.totalCount60,
                        totalAmount120:_res.data.totalAmount120,
                        totalCount120:_res.data.totalCount120
                      })
                    setMyLoading(false)
                    setShowing(true)
                    setLoading(false)
                    setReload(true)
                    

                }
                if (_res.data.Radio === "ROOTCAUSE WISE") {
                console.log("rootcausechart",_res.data.RootCauseData)
                    setRootCauseDataWiseData(_res.data.RootCauseData)
                    setRootCauseTableData(_res.data.RootCauseTableData)
                    setRootCauseDataWiseDataForClaim(_res.data.RootCauseDataForClaim)
                    setGrandTotal({
                        totalAmount0:_res.data.totalAmount0,
                        totalCount0:_res.data.totalCount0,
                        totalAmount60:_res.data.totalAmount60,
                        totalCount60:_res.data.totalCount60,
                        totalAmount120:_res.data.totalAmount120,
                        totalCount120:_res.data.totalCount120
                      })
                    setMyLoading(false)
                    setShowing(true)
                    setLoading(false)
                    setReload(true)
                    

                }
                if (_res.data.Radio === "STATUS WISE") {
                    setUserList(_res.data.UserList)
                    setChartStatusWiseData(_res.data.StatusWise)
                    setStatusWiseTableData(_res.data.StatusWiseTableData)
                    setChartStatusWiseDataForClaim(_res.data.StatusWiseDataForClaim)
                    setGrandTotal({
                        totalAmount0:_res.data.totalAmount0,
                        totalCount0:_res.data.totalCount0,
                        totalAmount60:_res.data.totalAmount60,
                        totalCount60:_res.data.totalCount60,
                        totalAmount120:_res.data.totalAmount120,
                        totalCount120:_res.data.totalCount120
                      })
                    setMyLoading(false)
                    setShowing(true)
                    setLoading(false)
                    setReload(true)
                    

                }
                if (_res.data.Radio === "PROVIDER WISE") {
                    setUserList(_res.data.UserList)
                    setChartProviderData(_res.data.ProviderNameWise)
                    setChartProviderDataForClaim(_res.data.ProviderNameWiseForClaim)
                    setProviderNameWiseTableData(_res.data.ProviderNameWiseTableData)
                    setGrandTotal({
                        totalAmount0:_res.data.totalAmount0,
                        totalCount0:_res.data.totalCount0,
                        totalAmount60:_res.data.totalAmount60,
                        totalCount60:_res.data.totalCount60,
                        totalAmount120:_res.data.totalAmount120,
                        totalCount120:_res.data.totalCount120
                      })
                    setMyLoading(false)
                    setShowing(true)
                    setLoading(false)
                    setReload(true)
                    

                }
                if (_res.data.Radio === "AGING WISE") {
                    
                    setUserList(_res.data.UserList)
                    setChartAgeingWiseChartData(_res.data.AgeingWiseChartData)
                    setAgeingWiseTableData(_res.data.AgeingWiseTableData)
                    setChartAgeingWiseChartDataForClaim(_res.data.AgeingWiseChartDataForClaim)
                    setAgingTotal({
                        totalAmount:_res.data.totalAmount,
                        totalCount:_res.data.totalCount
                      })
                    setMyLoading(false)
                    setShowing(true)
                    setLoading(false)
                    setReload(true)
                    

                }

            })
        }
        else {
            setSelectedPractice([])
        }
    }

    const handleRadioChange = (e: any) => {
        fetchData(e.target.value)
       
    }

    const Reload=()=>{
        fetchData(selectedRadio)
    }

    const FetchBackData=async()=>{
        setDisabled(false)
 
        setUserList([])
        setChartData([])
        setPayerNameTableData([])
        setRootCauseDataWiseData([])
        setRootCauseTableData([])
        setChartStatusWiseData([])
        setChartStatusWiseDataForClaim([])
        setStatusWiseTableData([])
        setChartProviderData([])
        setChartProviderDataForClaim([])
        setProviderNameWiseTableData([])
        setChartAgeingWiseChartData([])
        setAgeingWiseTableData([])
        setChartAgeingWiseChartDataForClaim([])
        setRootCauseDataWiseDataForClaim([])
        setGrandTotal({
            totalAmount0:0,
            totalCount0:0,
            totalAmount60:0,
            totalCount60:0,
            totalAmount120:0,
            totalCount120:0
        })
        setAgingTotal({
            totalAmount:0,
            totalCount:0
          })
      

        // setLoading(true)
        
        if(sessionStorage.getItem("PmSystemPr")){
            const PmSystemData:any=sessionStorage.getItem("PmSystemPr")
        PmSystemPr=JSON.parse(PmSystemData)

        const PracticeData:any=sessionStorage.getItem("PracticePr")
        PracticePr=JSON.parse(PracticeData)

        const RadioData:any=sessionStorage.getItem("RadioPr")
        RadioPr=JSON.parse(RadioData)

            setSelectedPmSystem(PmSystemPr)
            setSelectedPractice(PracticePr)
            setSelectedRadio(RadioPr)

            axios.post(getPracticeList, { PostData: PmSystemPr.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }
     

                 if(RadioPr === "PAYER WISE"){
                    setRadioChecked({PayerRadio:true})
                 } else if(RadioPr === "AGING WISE"){
                    setRadioChecked({AgingRadio:true})
                 } else if(RadioPr === "STATUS WISE"){
                    setRadioChecked({StatusRadio:true})
                 } else if(RadioPr === "PROVIDER WISE"){
                    setRadioChecked({ProviderRadio:true})
                 } else if(RadioPr === "ROOTCAUSE WISE"){
                    setRadioChecked({RootCauseRadio:true})
                 } 

                 if(PracticePr.length >0){
                    setRadioDisabled(false)
        axios.get(`${getPracticewiseDashboard}?PracticeId=${JSON.stringify(PracticePr)}&radio=${RadioPr}`).then(_res => {
            if (_res.data.Radio === "PAYER WISE") {
               
                setChartData(_res.data.PayerNameData)
                setChartDataForClaim(_res.data.PayerNameDataForClaim)
                setPayerNameTableData(_res.data.PayerNameDataTable)
                setGrandTotal({
                    totalAmount0:_res.data.totalAmount0,
                    totalCount0:_res.data.totalCount0,
                    totalAmount60:_res.data.totalAmount60,
                    totalCount60:_res.data.totalCount60,
                    totalAmount120:_res.data.totalAmount120,
                    totalCount120:_res.data.totalCount120
                  })
                setMyLoading(false)
                setShowing(true)
                setLoading(false)
                setReload(true)
                

            }
            if (_res.data.Radio === "ROOTCAUSE WISE") {
            console.log("rootcausechart",_res.data.RootCauseData)
                setRootCauseDataWiseData(_res.data.RootCauseData)
                setRootCauseTableData(_res.data.RootCauseTableData)
                setRootCauseDataWiseDataForClaim(_res.data.RootCauseDataForClaim)
                setGrandTotal({
                    totalAmount0:_res.data.totalAmount0,
                    totalCount0:_res.data.totalCount0,
                    totalAmount60:_res.data.totalAmount60,
                    totalCount60:_res.data.totalCount60,
                    totalAmount120:_res.data.totalAmount120,
                    totalCount120:_res.data.totalCount120
                  })
                setMyLoading(false)
                setShowing(true)
                setLoading(false)
                setReload(true)
                

            }
            if (_res.data.Radio === "STATUS WISE") {
                setUserList(_res.data.UserList)
                setChartStatusWiseData(_res.data.StatusWise)
                setStatusWiseTableData(_res.data.StatusWiseTableData)
                setChartStatusWiseDataForClaim(_res.data.StatusWiseDataForClaim)
                setGrandTotal({
                    totalAmount0:_res.data.totalAmount0,
                    totalCount0:_res.data.totalCount0,
                    totalAmount60:_res.data.totalAmount60,
                    totalCount60:_res.data.totalCount60,
                    totalAmount120:_res.data.totalAmount120,
                    totalCount120:_res.data.totalCount120
                  })
                setMyLoading(false)
                setShowing(true)
                setLoading(false)
                setReload(true)
                

            }
            if (_res.data.Radio === "PROVIDER WISE") {
                setUserList(_res.data.UserList)
                setChartProviderData(_res.data.ProviderNameWise)
                setChartProviderDataForClaim(_res.data.ProviderNameWiseForClaim)
                setProviderNameWiseTableData(_res.data.ProviderNameWiseTableData)
                setGrandTotal({
                    totalAmount0:_res.data.totalAmount0,
                    totalCount0:_res.data.totalCount0,
                    totalAmount60:_res.data.totalAmount60,
                    totalCount60:_res.data.totalCount60,
                    totalAmount120:_res.data.totalAmount120,
                    totalCount120:_res.data.totalCount120
                  })
                setMyLoading(false)
                setShowing(true)
                setLoading(false)
                setReload(true)
                

            }
            if (_res.data.Radio === "AGING WISE") {
                
                setUserList(_res.data.UserList)
                setChartAgeingWiseChartData(_res.data.AgeingWiseChartData)
                setAgeingWiseTableData(_res.data.AgeingWiseTableData)
                setChartAgeingWiseChartDataForClaim(_res.data.AgeingWiseChartDataForClaim)
                setAgingTotal({
                    totalAmount:_res.data.totalAmount,
                    totalCount:_res.data.totalCount
                  })
                setMyLoading(false)
                setShowing(true)
                setLoading(false)
                setReload(true)
                

            }
            
    })
}
else{
                    
    setRadioDisabled(true)
}
    }
    
   
      const DownloadFile = async (e:any)=>{
        setMyLoading(true)
        e.preventDefault();
        await axios.get(e.target.href,{responseType: 'arraybuffer',}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Claims-${moment().format("YYYYMMDDhms")}.xlsx`);
            document.body.appendChild(link);
            setMyLoading(false)
            link.click();
        }).catch((error) => console.log(error));
        return false;
    }

    const getPMSystem = (event: any) => {
        setSelectedPractice([])
       {(Object.keys(SelectedPractice).length >0) && setRadioDisabled(!RadioDisabled)}

        setSelectedPmSystem(event)
        sessionStorage.setItem("PmSystemPr",JSON.stringify(event))
        if (event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }

    };
   


    var UsersListData = [{ label: "Select", value: "" }]
    UserNameDatas.map((userList, index) => {
        UsersListData.push({
            label: userList.FirstName,
            value: userList._id
        })
    })
    var PmListData = [{ label: "Select", value: "" }]
    getPmListData.map((name) => (
        PmListData.push({
            label: name.PmName,
            value: name._id
        })
    ))

    return (

        <div>
<div className="header">
      <span className="section">Dashboard</span>
      <span className="arrow">&rsaquo;</span>
      <span className='section'>Practice Dashboard</span>
    </div>

            
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={myLoading}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                {/* reports */}
                <div className='d-flex '>
                    <div className='card-title m-0 flex-fill'>
                        <h3 className='fw-bolder m-0'>Practice Wise Reports</h3>
                    </div>
                   
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>PM System</span>
                    </label>
                    <br />
                    <Select
                        maxMenuHeight={180}
                        value={SelectedPmSystem}
                        onChange={getPMSystem}
                        id='pm_system'
                        name={'pm_system'}
                        options={PmListData}
                    />
                </div>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span className='required'>Practice Name</span>
                    </label>

                    <Select
                        maxMenuHeight={180}
                        value={SelectedPractice}
                        isMulti
                        onChange={getPractice}
                        id='Practice'
                        name={'Practice'}
                        options={PracticeListData}
                    />
                </div>

                <div className='col-1 pt-13 mx-6'>
                  {reload ? (
 <button 
 onClick={Reload}
     className='btn btn-primary ' >Reload</button>
                  ):(
                    <button 
                    // onClick={Filter}
                        className='btn btn-primary disabled' >Reload</button>
                  )} 
                </div>
               
                <div className='col-1 pt-13'>
                    <a
                        onClick={(e) => { DownloadFile(e) }}
                        href={`/users/PracticewiseDownloadDashboard?PracticeId=${JSON.stringify(
                            SelectedPractice
                        )}`}
                        className='btn btn-primary'
                    >
                        Download
                    </a>
                </div>
            </div>
            <hr />

            <div className='row'>
                <h4 className='mb-6'>Filter reports by</h4>
                <div className='radios mb-6 d-flex justify-content-around'>
                    <div className='form-check form-check-inline'>
                        <input
                            checked={RadioChecked.PayerRadio}
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='flexRadioDefault1'
                            value='PAYER WISE'
                            onChange={handleRadioChange}
                           disabled={RadioDisabled}
                        />
                        <label className='form-check-label' htmlFor='flexRadioDefault1'>
                            PAYER WISE
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                        checked={RadioChecked.AgingRadio}
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='flexRadioDefault2'
                            value='AGING WISE'
                            onChange={handleRadioChange}
                            disabled={RadioDisabled}
                        />
                        <label className='form-check-label' htmlFor='flexRadioDefault2'>
                            AGING WISE
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                        checked={RadioChecked.ProviderRadio}
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='flexRadioDefault2'
                            value='PROVIDER WISE'
                            onChange={handleRadioChange}
                            disabled={RadioDisabled}
                        />
                        <label className='form-check-label' htmlFor='flexRadioDefault2'>
                            PROVIDER WISE
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                        checked={RadioChecked.StatusRadio}
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='flexRadioDefault2'
                            value='STATUS WISE'
                            onChange={handleRadioChange}
                             disabled={RadioDisabled}
                        />
                        <label className='form-check-label' htmlFor='flexRadioDefault2'>
                            STATUS WISE
                        </label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input
                        checked={RadioChecked.RootCauseRadio}
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            id='flexRadioDefault2'
                            value='ROOTCAUSE WISE'
                            onChange={handleRadioChange}
                            disabled={RadioDisabled}
                        />
                        <label className='form-check-label' htmlFor='flexRadioDefault2'>
                            ROOTCAUSE WISE
                        </label>
                    </div>



                </div>

                <div>
                    <div className='d-flex flex-row justify-content-around '>
                    
                            {ChartData?.length > 1 ? (
                                  <div className='col-5 shadow'>
                                    <Chart
                                        chartType='ColumnChart'
                                        width='100%'
                                        height='600px'
                                        data={ChartDataForClaim}
                                        options={ChartOptionsForClaim}
                                    />
                                </div>

                            ) : null}

                      
                            {ChartData?.length > 1 ? (
                          <div className='col-5 shadow '>
                                    <Chart
                                        chartType='ColumnChart'
                                        width='100%'
                                        height='600px'
                                        data={ChartData}
                                        options={ChartOptions}
                                    />
                                </div>
                            ) : null}
                    </div>
                    
                        {PayerNameTableData?.length > 0 ? (
                             
                             <TableRows data={PayerNameTableData} total={GrandTotal} title="PAYER WISE TABLE"/>
        )  : 
        (PayerNameTableData?.length === 0 && selectedRadio === "PAYER WISE")?
            <h4 className='text-center'>No Results Found</h4>
          
             : null

        }
                </div>


               {/* /Status wise */}

                <div>
                    <div className='d-flex flex-row justify-content-around'>
                    
                        {ChartStatusWiseDataForClaim.length > 1 ? (
                       <div className='col-5 shadow'>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={ChartStatusWiseDataForClaim}
                                    options={ChartOptionsStatusForClaim}
                                />
                            </div>
                        ) : null}

                        
                        {ChartStatusWiseData.length > 1 ? (
                    <div className='col-5 shadow '>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={ChartStatusWiseData}
                                    options={ChartOptionsStatus}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className=' mt-8'>

                        {StatusWiseTableData.length > 0 ? (
                              <TableRows data={StatusWiseTableData} total={GrandTotal} title="STATUS WISE TABLE"/>
                        ) :  (StatusWiseTableData?.length === 0 && selectedRadio === "STATUS WISE")?
                   
                           <h4 className='text-center'>No Results Found </h4>
                         
                            : null}
                    </div>


                </div>



            {/* Provider wise */}

                <div>
                    <div className='d-flex flex-row justify-content-around'>
                  
                        {ChartProviderDataForClaim.length > 1 ? (
                      <div className='col-5 shadow'>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={ChartProviderDataForClaim}
                                    options={ChartOptionsProviderForClaim}
                                />
                            </div>
                        ) : null}

           

                        {ChartProviderData.length > 1 ? (
                         <div className='col-5 shadow '>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={ChartProviderData}
                                    options={ChartOptionsProvider}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className=' mt-8'>

                        {ProviderNameWiseTableData.length > 0 ? (
                            <TableRows data={ProviderNameWiseTableData} total={GrandTotal} title="PROVIDER WISE TABLE"/>
                        ) :  (ProviderNameWiseTableData?.length === 0 && selectedRadio === "PROVIDER WISE")?
                     
                           <h4 className='text-center'>No Results Found</h4>
                          
                         : null}
                    </div>

                </div>




                {/* Aging Wise */}
                <div>
                    <div className='d-flex flex-row justify-content-around'>
                 
                        {ChartAgeingWiseChartDataForClaim.length > 1 ? (
                           <div className='col-5 shadow '>
                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={ChartAgeingWiseChartDataForClaim}
                                    options={ChartOptionsPracticeForClaim}
                                />
                            </div>
                        ) : null}

                        {ChartAgeingWiseChartData.length > 1 ? (
                          <div className='col-5 shadow'>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={ChartAgeingWiseChartData}
                                    options={ChartOptionsPractice}
                                />
                            </div>
                        ) : null}

                    </div>
                </div>
                <div className='d-flex justify-content-center mt-8'>
                    {AgeingWiseTableData?.length > 0 ? (
                         <AgingTable data={AgeingWiseTableData} total={AgingTotal} title="AGING WISE TABLE"/>
                    ) :(AgeingWiseTableData?.length === 0 && selectedRadio === "AGING WISE")?
                     
                    <h4 className='text-center'>No Results Found</h4>: null}
                </div>

                <br />

                <div>

                    <div className='d-flex flex-row justify-content-around'>
                        {RootCauseDataWiseDataForClaim.length > 1 ? (
                          <div className='col-5 shadow'>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={RootCauseDataWiseDataForClaim}
                                    options={ChartOptionsRootCauseForClaim}
                                />
                            </div>
                        ) : null}
                        {RootCauseDataWiseData.length > 1 ? (
                                            <div className='col-5 shadow '>

                                <Chart
                                    chartType='ColumnChart'
                                    width='100%'
                                    height='600px'
                                    data={RootCauseDataWiseData}
                                    options={ChartOptionsRootCause}
                                />
                            </div>
                        ) : null}
                    </div>

                </div>

                <div className=' mt-8'>
                    {RootCauseTableData?.length > 0 ? (
                        <TableRows data={RootCauseTableData} total={GrandTotal} title="ROOTCAUSE WISE TABLE"/>
                    ) : 
                     (RootCauseTableData?.length === 0 && selectedRadio === "ROOTCAUSE WISE")?
        
                       <h4 className='text-center'>No Results Found</h4>
                      
                    : null}
                </div>

            </div>
        </div>
    )
}
export { Practicewise }