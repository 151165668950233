import React, { useState, useEffect } from 'react'
import axios from "axios";
import Select from 'react-select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
const getAllPracticeList = `/api/practice/getAllPracticeList`
const getCountForPracticeNeedToCheck = "/api/NeedToCheck/getCountForPracticeNeedToCheck"

const BotNeedToCheckReport: React.FC = () => {

    const [SelectedPractice, setSelectedPractice] = useState([])
    const [SelectedPayer, setSelectedPayer] = useState({ label: "", value: "" })
    const [PracticeListData, setPracticeListData] = useState([])
    const [ListForPayer, setListForPayer]: any = useState([{ label: "All", value: "All" }, { label: "UHC", value: "UHC" }, { label: "Aetna", value: "Aetna" }, { label: "Humana", value: "Humana" }, { label: "BCBS", value: "BCBS" },{ value: "AMERICHOICE", label: "Ameri Choice" },{ value: "EMPIRE", label: "Empire BC Medicad Health Plus" }])
    const [isLoading, setLoading] = useState(false)
   
    const GetPracticeList = async () => {
        axios.post(getAllPracticeList).then((res: any) => {
            var GetPracticeData: any = [{ label: "All", value: "All" }]
            res.data.data.map((name: any) => (
                GetPracticeData.push({
                    label: name.label,
                    value: name.value
                })
            ))
            setPracticeListData(GetPracticeData)
        })
    }
    useEffect(() => {
        GetPracticeList()
    }, [])
    const getPractice = (event: any) => {

        if (Object.keys(event).length > 0) {
            setSelectedPractice(event)


        } else {
            setSelectedPractice([])

        }
    }
    const getPayer = (event: any) => {
        if (Object.keys(event).length > 0) {
            setSelectedPayer(event)
        } else {
            setSelectedPayer({value:"",label:""})
        }
    }
    const GetUtlizationbutton = async (e: any) => {
        e.preventDefault();
        setLoading(true)
        axios.get(`${getCountForPracticeNeedToCheck}?Payer=${SelectedPayer.value}&PracticeId=${JSON.stringify(SelectedPractice)}&type=download`).then((response) => {
            setLoading(false)
            const url = window.location.origin + "/" + response.data;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "");
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
    }
    return (
        <div >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="header">
                <span className="section">Bot Need To Check Claims</span>
            </div>

            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    {/* <h3 className='fw-bolder m-0'>View Claims</h3> */}
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-3 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span >Payer Name</span>
                    </label>
                    <Select maxMenuHeight={180} value={SelectedPayer} onChange={getPayer} id="Practice" name={"Practice"} options={ListForPayer} />

                </div>
                <div className='col-sm-3 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span className='required'>Practice Name</span>
                    </label>
                    <Select maxMenuHeight={180} value={SelectedPractice} isMulti onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />

                </div>
                <div className='col-1 pt-13'>
                    <button onClick={(e) => { GetUtlizationbutton(e) }} type="button" className='btn btn-primary'>Download</button>
                </div>
            </div>
        </div>
    )
}
export { BotNeedToCheckReport }