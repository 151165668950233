import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { LicenseInfo } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey('ed624b57d1a99f8311bb44a886b0ee08Tz02MzE0OSxFPTE3MTE4MTAwNDc4NTUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
