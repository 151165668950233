import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select';
import { DataGridPro, GridColDef, GridFilterModel, DataGridProProps, GridSortModel, GridRowParams, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridValueGetterParams, GridRowModel, getGridSingleSelectOperators, GridComparatorFn, GridFilterItem, GridCellParams } from '@mui/x-data-grid-pro';
import moment from "moment-timezone"
import { buttonBaseProps } from '../wizards/common/buttonBaseProp';
import { useAuth } from '.././../../app/modules/auth'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button, { ButtonProps } from '@mui/material/Button';
const getUserList = `/users/getUsersList`
const getPracticeList = `/api/practice/getPracticeListById`
const getColumnsByUser = `/api/Claims/getColumnsByUser`
const getPmList = `/api/pmsystem/getPmList`
const GetPracticeIdbyUsers = "/users/GetPracticeIdbyUsers"


const ViewSystemUsers: React.FC = () => {

    const [SelectedPmSystem, setSelectedPmSystem] = useState({})
    const [SelectedPractice, setSelectedPractice] = useState([])
    const [PracticeListData, setPracticeListData] = useState([])
    const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
    const [open, setOpen] = React.useState(false);
    const [UserWithRoles, setUserWithRoles] = useState([])
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        totalClaimBalance : 0.00,
        pageSize: {
            page: 0,
            pageSize: 100
        }
        // page: 1,
        // pageSize: 20
    })


    const GetPmList = async () => {
        const getPmListData = await axios.get(getPmList)
        if (getPmListData.data) {

            setdatas(getPmListData.data.data)
        }
    }


    const GetUsersList = async () => {
        const Result = await axios.get(getUserList)
        // setUserNameDatas(Result.data.data)
    }


    useEffect(() => {

        GetUsersList()
        GetPmList()
    }, [])



    const getPractice = (event: any) => {

        if (Object.keys(event).length > 0) {
            setSelectedPractice(event)


        } else {
            setSelectedPractice([])

        }

    }


    const getPMSystem = (event: any) => {
        //setSelectedPractice([])
        setSelectedPmSystem(event)
        if (event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }

    };

    var PmListData = [{ label: "Select", value: "" }]
    getPmListData.map((name) => (
        PmListData.push({
            label: name.PmName,
            value: name._id
        })
    ))

    const GetPracticeIdbyUsersbutton = async (e: any) => {
        e.preventDefault();
        setOpen(true)
        console.log("SelectedPractice",SelectedPractice)
        var getPracticeId  : any = SelectedPractice
        setPageState(old => ({ ...old, isLoading: true }))
        await axios.get(`${GetPracticeIdbyUsers}?PracticeId=${getPracticeId._id}&&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}`).then((response) => {
            
            setPageState(old => ({ ...old, isLoading: false, data: response.data.data, total: response.data.total}))
            
        }).catch((error) => console.log(error));
        return false;
    }
    const columns: GridColDef[] = [
        { align: 'center', headerAlign: "center", field: 'FirstName', headerName: 'First Name', width: 100, },
        { align: 'center', headerAlign: "center", field: 'LastName', headerName: 'Last Name', width: 100, },
        { align: 'center', headerAlign: "center", field: 'roleName', headerName: 'Role Name', width: 100, },
    ]
    const handleExport = async (_e: any) => {
        var getPracticeId  : any = SelectedPractice
        axios.get(`${GetPracticeIdbyUsers}?PracticeId=${getPracticeId._id}&&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&view=download`).then((response) => {
           
            const url = window.location.origin+"/"+response.data;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',"");
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
    }
    const CustomToolBar = () => {

        return (<GridToolbarContainer>

            <Button
                {...buttonBaseProps}
                onClick={(e) => handleExport(e)}
            >Export</Button>
        </GridToolbarContainer>
        );
    }
    return (
        <div >

            <div className="header">
                <span className="section">Claim Management</span>
                <span className="arrow">&rsaquo;</span>
                <span className='section'>View System Users</span>
            </div>
            
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    {/* <h3 className='fw-bolder m-0'>View Claims</h3> */}
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>PM System</span>
                    </label><br />
                    <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmSystem" name={"PmSystem"} options={PmListData} />

                </div>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span className='required'>Practice Name</span>
                    </label>

                    <Select maxMenuHeight={180} value={SelectedPractice}  onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />

                </div>
                <div className='col-1 pt-13'>
                    <button onClick={(e) => { GetPracticeIdbyUsersbutton(e) }} type="button" className='btn btn-primary'>Submit</button>
                </div>

            </div>
            <div style={{ width: "50%", }} className="pt-5">
                <DataGridPro
                    rowHeight={40}
                    autoHeight={true}
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    pageSizeOptions={[100, 200, 300, 400, 500]}
                    paginationModel={{
                        page: pageState.pageSize.page == 0 ? pageState.pageSize.page : pageState.pageSize.page,
                        pageSize: pageState.pageSize.pageSize
                    }}
                    paginationMode="server"
                    onPaginationModelChange={(newPageSize: any) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                    pagination
                    columns={columns}
                    components={{ Toolbar: CustomToolBar }}
                    density="compact"
                />
            </div>
        </div>


    )

}
export { ViewSystemUsers }